import React, {useMemo,useCallback, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";


const MultipleAnswer = () => {
  const location = useLocation();
  // const getData = () => {
  //   const data = location.state?.data;
  
  //   if (!Array.isArray(data)) {
  //     console.error("getData| Data is not in the expected format:", data);
  //     return [];
  //   }
  
  //   // Transforming the input data to the required flashcards format
  //   const cards = data.map((item) => ({
  //     front: item.content,
  //     back: item.translatedContent,
  //     // imageSrc: item.imageSrc,
  //     // englishHint: item.englishHint,
  //     // vietnameseHint: item.vietnameseHint,
  //     // wordType: item.wordType,
  //     // id: item.id, // Retain ID for unique identification if needed
  //   }));
  //   return cards;
  // };

  const getData = useCallback(() => {
    const data = location.state?.data;
  
    if (!Array.isArray(data)) {
      console.error("getData| Data is not in the expected format:", data);
      return [];
    }
  
    const cards = data.map((item) => ({
      front: item.content,
      back: item.translatedContent,
      imageSrc: item.imageSrc,
      englishHint: item.englishHint,
      vietnameseHint: item.vietnameseHint,
      wordType: item.wordType,
      id: item.id,
    }));
    return cards;
  }, [location.state?.data]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [feedbackColor, setFeedbackColor] = useState('');
  // const flashcards = useMemo(() => getData(), [location.state?.data]);

  // const flashcards =  getData()

  const flashcards = useMemo(() => getData(), [getData]);



  // const flashcards = useMemo(() => getData(), [location.state?.data, getData]);


  useEffect(() => {
    console.log("==== index |", currentIndex)

    console.log("==== len flashcards |", flashcards.length)

    const currentCard = flashcards[currentIndex];
    const incorrectAnswers = new Set();
    // Generate 3 incorrect answers
    while (incorrectAnswers.size < 3) {
      const randomCard = flashcards[Math.floor(Math.random() * flashcards.length)];
      if (randomCard.back !== currentCard.back) {
        incorrectAnswers.add(randomCard.back);
      }
    }
    // Combine correct and incorrect answers and shuffle
    const allAnswers = [currentCard.back, ...Array.from(incorrectAnswers)];
    setAnswers(shuffleArray(allAnswers));
    setCorrectAnswer(currentCard.back);
    setSelectedAnswer(null);
    setIsAnswered(false);
    setFeedbackColor('');
  }, [currentIndex,flashcards]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswerSelect = (answer) => {
    if (isAnswered) return; // Prevent selecting another answer once answered

    setSelectedAnswer(answer);
    setIsAnswered(true); // Mark that the question has been answered

    // Check if the selected answer is correct
    if (answer === correctAnswer) {
      setFeedbackColor('lightgreen'); // Set feedback to green for correct answer
    } else {
      setFeedbackColor('salmon'); // Set feedback to red for incorrect answer
    }

    // Reset feedback color after 1 second
    setTimeout(() => {
      setFeedbackColor('');
      handleNext(); // Move to the next question after resetting
    }, 1000); // 1 second delay before resetting
  };

  const handleNext = () => {
    // Move to the next question
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  const currentCard = flashcards[currentIndex];

  // Styles for buttons and animations
  const buttonStyle = (answer) => ({
    backgroundColor: selectedAnswer === answer
      ? feedbackColor || 'lightgray' // Use feedback color if selected
      : 'lightgray',
    color: 'black',
    border: 'none',
    borderRadius: '5px',
    padding: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  });

  const containerStyle = {
    textAlign: 'center',
    margin: '20px',
  };

  const gridStyle = {
    margin: '20px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '10px',
  };

  const questionStyle = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={questionStyle}>{currentCard.front}</h2>
      <div style={gridStyle}>
        {answers.map((answer, index) => (
          <button
            key={index}
            style={buttonStyle(answer)}
            onClick={() => handleAnswerSelect(answer)}
            disabled={isAnswered} // Disable selection after answering
          >
            {answer}
          </button>
        ))}
      </div>
      {isAnswered && (
        <div>
          <p style={{ fontSize: '16px', marginTop: '10px' }}>
            {/* {selectedAnswer === correctAnswer ? 'Correct!' : 'Incorrect!'} */}
          </p>
          {/* <button onClick={handleNext} style={{ padding: '10px 15px', borderRadius: '5px', marginTop: '10px' }}>
            Next
          </button> */}
        </div>
      )}
      <p style={{ marginTop: '20px' }}>Question {currentIndex + 1} / {flashcards.length}</p>
    </div>
  );
};

export default MultipleAnswer;
