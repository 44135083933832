

import React, { useCallback, useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";


const CardWriting = () => {
  const location = useLocation();
  const getData = () =>{
    const data = location.state?.data;
  
    if (!Array.isArray(data)) {
      console.error("getData| Data is not in the expected format:", data);
      return [];
    }
  
    const cards = data.map((item) => ({
      front: item.content,
      back: item.translatedContent,
      imageSrc: item.imageSrc,
      englishHint: item.englishHint,
      vietnameseHint: item.vietnameseHint,
      wordType: item.wordType,
      id: item.id,
    }));
    return cards;
  }
  // const initialCards = [
  //   { id: "1", front: 'Apple', back: 'Quả táo' },
  //   { id: "2", front: 'Banana', back: 'Quả chuối' },
  //   { id: "3", front: 'Cat', back: 'Con mèo' },
  //   { id: "4", front: 'Dog', back: 'Con chó' },
  //   { id: "5", front: 'Elephant', back: 'Con voi' },
  // ];

  const initialCards = getData()

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [completed, setCompleted] = useState(false);
  const [shakeEffect, setShakeEffect] = useState('');
  const navigate = useNavigate();

  // const moveToNextCard = () => {
  //   if (currentCardIndex < initialCards.length - 1) {
  //     setCurrentCardIndex(currentCardIndex + 1);
  //     setInputValue('');
  //   } else {
  //     setCompleted(true);
  //   }
  // };

  const moveToNextCard = useCallback(() => {
    if (currentCardIndex < initialCards.length - 1) {
      setCurrentCardIndex((prevIndex) => prevIndex + 1);
      setInputValue('');
    } else {
      setCompleted(true);
    }
  }, [currentCardIndex, initialCards.length]);

  // const handleRestart = () => {
  //   setCurrentCardIndex(0);
  //   setInputValue('');
  //   setCompleted(false);
  // };

  const handleRestart = () => {
    setCurrentCardIndex(0);
    setInputValue('');
    setCompleted(false);
  };

  const handleGoToList = () => {
    navigate("/list");
  };

  const currentCard = initialCards[currentCardIndex];

  const removeDiacritics = (input) => {
    const diacriticsMap = {
      a: /[àáạảãâầấậẩẫăằắặẳẵ]/g,
      e: /[èéẹẻẽêềếệểễ]/g,
      i: /[ìíịỉĩ]/g,
      o: /[òóọỏõôồốộổỗơờớợởỡ]/g,
      u: /[ùúụủũưừứựửữ]/g,
      y: /[ỳýỵỷỹ]/g,
      d: /[đ]/g,
      A: /[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g,
      E: /[ÈÉẸẺẼÊỀẾỆỂỄ]/g,
      I: /[ÌÍỊỈĨ]/g,
      O: /[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g,
      U: /[ÙÚỤỦŨƯỪỨỰỬỮ]/g,
      Y: /[ỲÝỴỶỸ]/g,
      D: /[Đ]/g,
    };

    let output = input;
    for (const [nonDiacritic, regex] of Object.entries(diacriticsMap)) {
      output = output.replace(regex, nonDiacritic);
    }
    return output.toLowerCase();
  };

  useEffect(() => {
    if (inputValue.length === currentCard.back.length) {
      if (removeDiacritics(inputValue) === removeDiacritics(currentCard.back)) {
        setShakeEffect('correct');
        setTimeout(() => {
          setShakeEffect('');
          moveToNextCard();
        }, 1000);
      } else {
        setShakeEffect('incorrect');
        setTimeout(() => {
          setShakeEffect('');
          setInputValue('');
        }, 1000);
      }
    }
  }, [inputValue, currentCard, moveToNextCard]);

  const handleKeyDown = (e) => {
    // Đừng cho phép nhập vào khoảng trắng
    const charIndex = inputValue.length; // Chỉ số ký tự tiếp theo để nhập
    const charAtIndex = currentCard.back[charIndex];

    if (e.key === 'Backspace') {
      setInputValue((prev) => prev.slice(0, -1));
    } else if (e.key.length === 1 && charAtIndex !== ' ') {
      setInputValue((prev) => prev + e.key);
    } else if (e.key.length === 1 && charAtIndex === ' ') {
        if (e.key === ' '){
            setInputValue((prev) => prev + e.key);
        } else if (e.key !== ' '){
            setInputValue((prev) => prev + ' '+ e.key);
        }
    }
  };



  // Tạo các dấu gạch chân cho từng ký tự
  const displayedText = currentCard.back.split('').map((char, index) => {
    const inputChar = inputValue[index] || ''; // Lấy ký tự đã nhập hoặc để trống

    if (char === ' ') {
      // Hiển thị khoảng trắng cho ký tự khoảng trắng
      return (
        <span
      key={index}
      style={{ marginRight: '2px', color: 'white' }}
      dangerouslySetInnerHTML={{ __html: '&nbsp; &nbsp;' }}
        />
      );
    }

    return (
      <span key={index} style={{ marginRight: '2px' }}>
        {inputChar ? inputChar : '_'}
      </span>
    );
  });

  return (
    <div style={containerStyle} onKeyDown={handleKeyDown} tabIndex={0}>
      {completed ? (
        <div style={completionStyle}>
          <h2>Bạn đã hoàn thành bài học!</h2>
          <div style={buttonContainerStyle}>
            <Button type="primary" block style={buttonStyle} onClick={handleRestart}>
              Restart
            </Button>
            <Button type="primary" block style={buttonStyle} onClick={handleGoToList}>
              Lesson List
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            ...cardStyle,
            backgroundColor: shakeEffect === 'correct' ? 'lightgreen' : shakeEffect === 'incorrect' ? 'lightcoral' : 'white',
            animation: shakeEffect ? `${shakeEffect === 'correct' ? 'shakeGreen' : 'shakeRed'} 0.5s` : 'none',
          }}
          tabIndex={0}
        >
          <h3 style={frontTextStyle}>{currentCard.front}</h3>
          <div style={inputStyle}>
            {displayedText}
          </div>
        </div>
      )}
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  outline: 'none', // To remove the focus outline
};

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  width: '300px',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  backgroundColor: '#fff',
  transition: 'background-color 0.3s',
};

const frontTextStyle = {
  fontSize: '24px', // Larger text for the card front
  fontWeight: 'bold',
};

const completionStyle = {
  textAlign: 'center',
};

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '200px',
};

const buttonStyle = {
  height: '40px',
};

const inputStyle = {
  fontSize: '18px',
  width: '100%',
};

// Keyframe animations
const shakeKeyframes = `
@keyframes shakeGreen {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}
@keyframes shakeRed {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}`;

document.head.insertAdjacentHTML('beforeend', `<style>${shakeKeyframes}</style>`);

export default CardWriting;

