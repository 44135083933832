import React, { useState } from 'react';

const WritingPage = () => {
  const sentences = [
    {
        id: "s1",
        original: "I enjoy reading books in the evening. It's a relaxing activity.",
        translation: "Tôi thích đọc sách vào buổi tối. Đó là một hoạt động thư giãn.",
    },
    {
        id: "s2",
        original: "She goes to the gym every morning to stay healthy.",
        translation: "Cô ấy đi đến phòng tập mỗi sáng để giữ sức khỏe.",
    },
    {
        id: "s3",
        original: "They are planning a trip to Japan next year.",
        translation: "Họ đang lên kế hoạch cho chuyến đi Nhật Bản vào năm tới.",
    },
  ];

  const [currentSentence, setCurrentSentence] = useState(sentences[0]);
  const [inputText, setInputText] = useState('');
  const [isCorrect, setIsCorrect] = useState(null);
  const [showOriginal, setShowOriginal] = useState(false);

  const hintText = currentSentence.original.replace(/[a-zA-Z0-9]/g, '_');

  // Chuyển sang câu mới
  const nextSentence = () => {
    const currentIndex = sentences.findIndex(
        (sentence) => sentence.id === currentSentence.id
      );

    const nextIndex = (currentIndex + 1) % sentences.length;
    setCurrentSentence(sentences[nextIndex]);
    setInputText(''); // Reset input
    setIsCorrect(null); // Reset trạng thái
  };

  // Kiểm tra câu trả lời
  const checkAnswer = () => {
    if (inputText.trim() === currentSentence.original) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', maxWidth: '80%' }}>
      <h2>Viết Câu Theo Nghĩa</h2>

      {/* Hiển thị câu dịch */}
      <p style={{ fontSize: '18px' }}>
        Translation: {currentSentence.translation}
      </p>

       {/* Hiển thị câu gốc hoặc gợi ý */}
       <p style={{ marginTop: '20px', fontSize: '18px' }}>
        Original: {showOriginal ? currentSentence.original : hintText}
      </p>

      {/* Tùy chọn hiển thị gợi ý hoặc câu gốc */}
      <label>
        <input
          type="checkbox"
          checked={showOriginal}
          onChange={(e) => setShowOriginal(e.target.checked)}
        />
        Show Original Text
      </label>

     

      {/* Nhập câu trả lời */}
      <textarea
        rows="4"
        cols="50"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Enter the correct sentence here"
        style={{
          width: '100%',
          padding: '10px',
          fontSize: '16px',
          marginTop: '10px',
          border: '1px solid #ccc',
          boxSizing: 'border-box',
        }}
      />

      {/* Nút kiểm tra đáp án */}
      <button
        onClick={checkAnswer}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          marginTop: '10px',
        }}
      >
        Check
      </button>

      {/* Nút chuyển câu mới */}
      <button
        onClick={nextSentence}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#2196F3',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          marginTop: '10px',
          marginLeft: '10px',
        }}
      >
        Next!
      </button>

      {/* Thông báo trạng thái kiểm tra đáp án */}
      {isCorrect !== null && (
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
            fontSize: '18px',
            color: isCorrect ? 'green' : 'red',
          }}
        >
          {isCorrect ? "Correct! Well done." : "Incorrect, try again."}
        </div>
      )}
    </div>
  );
};

export default WritingPage;
