import React from 'react';
import { useNavigate } from 'react-router-dom';

const DictationPage = () => {
  const navigate = useNavigate();

  // Danh sách các chủ đề ngữ pháp với thông tin thêm về số lần học và tỉ lệ đúng
  const grammarTopics = [
    { id: 1, title: 'IPA', attempts: 5, correctRate: 80 },
    { id: 2, title: 'Number', attempts: 8, correctRate: 90 },
    { id: 3, title: 'Spelling Name', attempts: 4, correctRate: 75 },
    { id: 4, title: 'IELTS Listening', attempts: 6, correctRate: 85 },
    { id: 5, title: 'TOEIC Listening', attempts: 7, correctRate: 88 },
    { id: 6, title: 'TED', attempts: 6, correctRate: 85 },
    { id: 7, title: 'Conversation', attempts: 7, correctRate: 88 },
  ];

  // Hàm điều hướng khi người dùng nhấn vào một chủ đề
  const handleTopicClick = (topicId) => {
    navigate(`/dictation/ipa`); // Điều hướng đến trang học flashcard của chủ đề
  };

  // Style tối giản với lưới cho các chủ đề
  const styles = {
    container: {
      // padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      maxWidth: '100%',
      margin: '0 auto',
    },
    title: {
      fontSize: '21px',
      // fontWeight: 'bold',
      marginBottom: '20px',
      textAlign: 'left',
    },
    topicList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '15px',
      marginTop: '20px',
    },
    topicItem: {
      padding: '15px',
      backgroundColor: '#ffffff',
      border: '1px solid #ddd',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '18px',
      transition: 'background-color 0.3s ease',
    },
    topicItemHover: {
      backgroundColor: '#4a90e2',
      color: '#fff',
    },
    idNumber: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#4a90e2',
      marginRight: '10px',
    },
    topicTitle: {
      fontSize: '16px',
      fontWeight: 'normal',
    },
    stats: {
      fontSize: '14px',
      color: '#888',
      marginTop: '8px',
    },
    correctRate: {
      fontWeight: 'bold',
      color: '#4a90e2',
    },
  };

  return (
    <div style={styles.container}>
      {/* <h1 style={styles.title}>All Topics</h1> */}
      <div style={styles.topicList}>
        {grammarTopics.map((topic) => (
          <div
            key={topic.id}
            style={styles.topicItem}
            onClick={() => handleTopicClick(topic.id)}
          >
            <div style={{  alignItems: 'center' }}>
              {/* <span style={styles.idNumber}>{topic.id}</span> */}
              <span>{topic.id}. {topic.title}</span>
            </div>
            <div style={styles.stats}>
              <span>Số lần học: {topic.attempts}</span>
              <br />
              <span>Tỉ lệ đúng: <span style={styles.correctRate}>{topic.correctRate}%</span></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DictationPage;

