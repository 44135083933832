// // src/index.js
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import 'slick-carousel/slick/slick.css'; // Nhập CSS của slick-carousel
// import 'slick-carousel/slick/slick-theme.css'; // Nhập theme của slick-carousel


// ReactDOM.render(
//     <BrowserRouter>
//         <App />
//     </BrowserRouter>,
//     document.getElementById('root')
// );

import './index.css'; // Đảm bảo đây là file chứa CSS của bạn


import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'slick-carousel/slick/slick.css'; // Nhập CSS của slick-carousel
import 'slick-carousel/slick/slick-theme.css'; // Nhập theme của slick-carousel

// Get the root DOM element
const rootElement = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(rootElement);

// Render your App component
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

