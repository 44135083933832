import React from 'react';
// import { useParams } from 'react-router-dom';
import {useNavigate } from 'react-router-dom';

import { Button } from 'antd';
import { AppstoreAddOutlined, EditOutlined, ContainerOutlined, ReadOutlined } from '@ant-design/icons';

const CardDetail = ({ content, englishHint, translatedHint, translatedContent, wordType, imageSrc }) => {
  const cardStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const contentStyle = {
    flex: 3,
    paddingRight: '20px',
  };

  const imageStyle = {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const textStyle = {
    marginBottom: '10px',
    fontSize: '16px',
  };

  const hintStyle = {
    fontSize: '14px',
    color: '#888',
  };

  const imageElementStyle = {
    maxWidth: '100%',
    maxHeight: '150px',
    borderRadius: '8px',
  };

  return (
    <div style={cardStyle}>
      <div style={contentStyle}>
        <p style={textStyle}><strong>{content}</strong> ({wordType}) </p>
        <p style={textStyle}><strong>Bản Dịch:</strong> {translatedContent}</p>

        {/* <p style={hintStyle}><strong>Type:</strong> {wordType}</p> */}
        <p style={hintStyle}><strong>English Hint:</strong> {englishHint}</p>
        <p style={hintStyle}><strong>Translated Hint:</strong> {translatedHint}</p>
      </div>
      <div style={imageStyle}>
        {imageSrc ? (
          <img src={imageSrc} alt="Card Illustration" style={imageElementStyle} />
        ) : (
          <p style={{ color: '#ccc', fontStyle: 'italic' }}>No Image Available</p>
        )}
      </div>
    </div>
  );
};

// export default CardDetail;

const FlashcardPage = () => {
  const navigate = useNavigate();
  // const { id } = useParams();

  // const flashcards = Array.from({ length: 20 }, (_, index) => ({
  //   id: (index + 1).toString(),
  //   content: `Acclimatize ${index + 1}`,
  //   englishHint: `English hint for card ${index + 1}`,
  //   vietnameseHint: `Vietnamese hint for card ${index + 1}`,
  //   translatedContent: `thích nghi với môi trường/khí hậu ${index + 1}`,
  //   wordType: `verb`,
  //   imageSrc:`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
  // }));
  const flashcards = [
    {
      "id":"1",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "divine",
      "englishHint": "Of, from, or like God or a god",
      "translatedHint": "Liên quan đến hoặc giống như Thượng đế hoặc một vị thần",
      "translatedContent": "thần thánh",
      "wordType": "adjective"
    },
    {
      "id":"2",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "gift",
      "englishHint": "A thing given willingly to someone without payment; a present",
      "translatedHint": "Một vật được tặng mà không đòi hỏi sự đền đáp; món quà",
      "translatedContent": "món quà",
      "wordType": "noun"
    },
    {
      "id":"3",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "randomly",
      "englishHint": "Without a specific pattern, order, or reason",
      "translatedHint": "Không có mẫu hình, trật tự hoặc lý do cụ thể",
      "translatedContent": "một cách ngẫu nhiên",
      "wordType": "adverb"
    },
    {
      "id":"4",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "delivered",
      "englishHint": "Brought or handed over to the proper recipient",
      "translatedHint": "Mang đến hoặc trao cho người nhận thích hợp",
      "translatedContent": "được trao tặng",
      "wordType": "verb"
    },
    {
      "id":"5",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "lightning",
      "englishHint": "The occurrence of a natural electrical discharge of very short duration and high voltage",
      "translatedHint": "Hiện tượng phóng điện tự nhiên ngắn và có điện áp cao",
      "translatedContent": "sét",
      "wordType": "noun"
    },
    {
      "id":"6",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "lava",
      "englishHint": "Molten rock expelled by a volcano during an eruption",
      "translatedHint": "Đá nóng chảy phun trào từ núi lửa trong một vụ phun trào",
      "translatedContent": "dung nham",
      "wordType": "noun"
    },
    {
      "id":"7",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "flame",
      "englishHint": "A hot glowing body of ignited gas",
      "translatedHint": "Một khối khí nóng phát sáng đang cháy",
      "translatedContent": "ngọn lửa",
      "wordType": "noun"
    },
    {
      "id":"8",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "stored",
      "englishHint": "Kept or accumulated for future use",
      "translatedHint": "Được giữ hoặc tích lũy để sử dụng sau này",
      "translatedContent": "được lưu trữ",
      "wordType": "verb"
    },
    {
      "id":"9",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "charcoal",
      "englishHint": "A porous black solid, used as a fuel",
      "translatedHint": "Một chất rắn đen xốp, được dùng làm nhiên liệu",
      "translatedContent": "than củi",
      "wordType": "noun"
    },
    {
      "id":"10",
      "imageSrc":`https://study4.com/media/vocabs_media/img/11_acclimatize.jpg`,
      "content": "pots",
      "englishHint": "Containers typically made of ceramic or metal, used for storage or cooking",
      "translatedHint": "Dụng cụ chứa, thường làm từ gốm hoặc kim loại, dùng để lưu trữ hoặc nấu ăn",
      "translatedContent": "nồi, bình",
      "wordType": "noun"
    }
  ]
  

  const handleModeSelect = (mode) => {
    console.log(`Selected mode: ${mode}`);
    const data = flashcards
    navigate(`/study/vocab/${mode}/1`,{ state: { data } });
    // navigate("/new-page", { state: { data } });

  };

  const pageStyle = {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  };

  const titleStyle = {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const infoBoxStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '20px',
    marginBottom: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f0f8ff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const infoItemStyle = {
    textAlign: 'center',
  };

  const infoTextStyle = {
    fontSize: '14px',
    color: '#555',
  };

  const infoNumberStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#1677ff',
  };

  const modeButtonContainer = {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
  };

  const modeContainerStyle = {
    textAlign: 'center',
  };

  const iconStyle = {
    fontSize: '28px',
    marginBottom: '5px',
  };

  const descriptionStyle = {
    fontSize: '14px',
  };

  const cardListStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  };

  return (
    <div style={pageStyle}>
      {/* Title */}
      <div style={titleStyle}>Bộ Từ Vựng chủ đề Thể Thao</div>

      {/* Info Box */}
      <div style={infoBoxStyle}>
        <div style={infoItemStyle}>
          <p style={infoTextStyle}>Tổng Số Từ</p>
          <p style={infoNumberStyle}>100</p>
        </div>
        <div style={infoItemStyle}>
          <p style={infoTextStyle}>Đã Học</p>
          <p style={infoNumberStyle}>40</p>
        </div>
        <div style={infoItemStyle}>
          <p style={infoTextStyle}>Đã Nhớ</p>
          <p style={infoNumberStyle}>25</p>
        </div>
        <div style={infoItemStyle}>
          <p style={infoTextStyle}>Cần Ôn Tập</p>
          <p style={infoNumberStyle}>35</p>
        </div>
      </div>

      {/* Mode Buttons */}
      <div style={modeButtonContainer}>
        <div style={modeContainerStyle}>
          <Button
            icon={<ReadOutlined style={iconStyle} />}
            onClick={() => handleModeSelect('flash-mode')}
            type="primary"
            shape="circle"
            size="large"
          />
          <p style={descriptionStyle}>Flash Mode</p>
        </div>
        <div style={modeContainerStyle}>
          <Button
            icon={<AppstoreAddOutlined style={iconStyle} />}
            onClick={() => handleModeSelect('multiple-answers')}
            type="primary"
            shape="circle"
            size="large"
          />
          <p style={descriptionStyle}>Multiple Answers</p>
        </div>
        <div style={modeContainerStyle}>
          <Button
            icon={<EditOutlined style={iconStyle} />}
            onClick={() => handleModeSelect('writing')}
            type="primary"
            shape="circle"
            size="large"
          />
          <p style={descriptionStyle}>Writing Mode</p>
        </div>
        <div style={modeContainerStyle}>
          <Button
            icon={<ContainerOutlined style={iconStyle} />}
            onClick={() => handleModeSelect('matching')}
            type="primary"
            shape="circle"
            size="large"
          />
          <p style={descriptionStyle}>Match List</p>
        </div>
      </div>

      {/* Card List */}
      <div style={cardListStyle}>
        {flashcards.map(card => (
          <CardDetail
            key={card.id}
            content={card.content}
            englishHint={card.englishHint}
            translatedHint={card.translatedHint}
            translatedContent={card.translatedContent}
            wordType={card.wordType}
            imageSrc={card.imageSrc}
          />
        ))}
      </div>
    </div>
  );
};

export default FlashcardPage;
