import React, { useState } from 'react';

const Speech = () => {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);

  const startListening = () => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US'; // Ngôn ngữ nhận dạng
      recognition.interimResults = false; // Chỉ trả về kết quả cuối cùng
      recognition.onstart = () => {
        setIsListening(true);
      };
      recognition.onend = () => {
        setIsListening(false);
      };
      recognition.onresult = (event) => {
        const result = event.results[0][0].transcript;
        setTranscript(result);
        console.log(`Confidence: ${event.results[0][0].confidence}`); // Độ chính xác
      };
      recognition.onerror = (error) => {
        console.error('Speech recognition error:', error);
      };
      recognition.start();
    } else {
      alert('Web Speech API không được hỗ trợ trên trình duyệt này.');
    }
  };

  return (
    <div style={{ textAlign: 'center', margin: '50px' }}>
      <h1>English Pronunciation Checker</h1>
      <button onClick={startListening} disabled={isListening}>
        {isListening ? 'Listening...' : 'Start Speaking'}
      </button>
      <p style={{ marginTop: '20px' }}>
        <strong>Transcript:</strong> {transcript || 'Say something...'}
      </p>
    </div>
  );
};

export default Speech;
