// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// const GrammarPage = () => {
//   const navigate = useNavigate();

//   // Danh sách các chủ đề ngữ pháp
//   const grammarTopics = [
//     { id: 1, title: 'Present Simple' },
//     { id: 2, title: 'Present Continuous' },
//     { id: 3, title: 'Past Simple' },
//     { id: 4, title: 'Past Continuous' },
//     { id: 5, title: 'Future Simple' },
//     { id: 6, title: 'Future Continuous' },
//     { id: 7, title: 'Present Perfect' },
//     { id: 8, title: 'Present Perfect Continuous' },
//     { id: 9, title: 'Past Perfect' },
//     { id: 10, title: 'Past Perfect Continuous' },
//     { id: 11, title: 'Future Perfect' },
//     { id: 12, title: 'Future Perfect Continuous' },
//     { id: 13, title: 'Conditionals' },
//     { id: 14, title: 'Modals of Advice' },
//     { id: 15, title: 'Modals of Ability' },
//     { id: 16, title: 'Modals of Obligation' },
//     { id: 17, title: 'Relative Clauses' },
//     { id: 18, title: 'Gerunds and Infinitives' },
//     { id: 19, title: 'Articles' },
//     { id: 20, title: 'Prepositions of Time' },
//     { id: 21, title: 'Prepositions of Place' },
//     { id: 22, title: 'Reported Speech' },
//     { id: 23, title: 'Indirect Questions' },
//     { id: 24, title: 'Question Tags' },
//     { id: 25, title: 'Quantifiers' },
//     { id: 26, title: 'Comparatives and Superlatives' },
//     { id: 27, title: 'Adverbs of Frequency' },
//     { id: 28, title: 'Passive Voice' },
//     { id: 29, title: 'Conditionals (Mixed)' },
//     { id: 30, title: 'Relative Pronouns' },
//   ];

//   // Hàm điều hướng khi người dùng nhấn vào một chủ đề
//   const handleTopicClick = (topicId) => {
//     navigate(`/grammar/${topicId}`); // Điều hướng đến trang học flashcard của chủ đề
//   };

//   // Style tối giản với lưới cho các chủ đề
//   const styles = {
//     container: {
//       padding: '20px',
//       fontFamily: 'Arial, sans-serif',
//       backgroundColor: '#ffffff',
//       maxWidth: '100%',
//       margin: '0 auto',
//     },
//     title: {
//       fontSize: '28px',
//       fontWeight: 'bold',
//       marginBottom: '20px',
//       textAlign: 'center',
//     },
//     topicList: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
//       gap: '15px',
//       marginTop: '20px',
//     },
//     topicItem: {
//       padding: '15px',
//       backgroundColor: '#ffffff',
//       border: '1px solid #ddd',
//       borderRadius: '5px',
//       cursor: 'pointer',
//       textAlign: 'center',
//       fontSize: '18px',
//       transition: 'background-color 0.3s ease',
//     },
//     topicItemHover: {
//       backgroundColor: '#4a90e2',
//       color: '#fff',
//     },
//     idNumber: {
//       fontSize: '14px',
//       fontWeight: 'bold',
//       color: '#4a90e2',
//       marginRight: '10px',
//     },
//     topicTitle: {
//       fontSize: '16px',
//       fontWeight: 'normal',
//     },
//   };

//   return (
//     <div style={styles.container}>
//       <h1 style={styles.title}>Ngữ pháp tiếng Anh</h1>
//       <div style={styles.topicList}>
//         {grammarTopics.map((topic) => (
//           <div
//             key={topic.id}
//             style={styles.topicItem}
//             onClick={() => handleTopicClick(topic.id)}
//           >
//             <div style={{ display: 'flex', alignItems: 'center' }}>
//               <span style={styles.idNumber}>{topic.id}</span>
//               <span style={styles.topicTitle}>{topic.title}</span>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default GrammarPage;




import React from 'react';
import { useNavigate } from 'react-router-dom';

const GrammarPage = () => {
  const navigate = useNavigate();

  // Danh sách các chủ đề ngữ pháp với thông tin thêm về số lần học và tỉ lệ đúng
  const grammarTopics = [
    { id: 1, title: 'Present Simple', attempts: 5, correctRate: 80 },
    { id: 2, title: 'Present Continuous', attempts: 8, correctRate: 90 },
    { id: 3, title: 'Past Simple', attempts: 4, correctRate: 75 },
    { id: 4, title: 'Past Continuous', attempts: 6, correctRate: 85 },
    { id: 5, title: 'Future Simple', attempts: 7, correctRate: 88 },
    { id: 6, title: 'Future Continuous', attempts: 3, correctRate: 70 },
    { id: 7, title: 'Present Perfect', attempts: 10, correctRate: 95 },
    { id: 8, title: 'Present Perfect Continuous', attempts: 5, correctRate: 80 },
    { id: 9, title: 'Past Perfect', attempts: 4, correctRate: 72 },
    { id: 10, title: 'Past Perfect Continuous', attempts: 2, correctRate: 60 },
    { id: 11, title: 'Future Perfect', attempts: 6, correctRate: 85 },
    { id: 12, title: 'Future Perfect Continuous', attempts: 8, correctRate: 90 },
    { id: 13, title: 'Conditionals', attempts: 7, correctRate: 78 },
    { id: 14, title: 'Modals of Advice', attempts: 9, correctRate: 92 },
    { id: 15, title: 'Modals of Ability', attempts: 3, correctRate: 75 },
    { id: 16, title: 'Modals of Obligation', attempts: 6, correctRate: 83 },
    { id: 17, title: 'Relative Clauses', attempts: 4, correctRate: 70 },
    { id: 18, title: 'Gerunds and Infinitives', attempts: 5, correctRate: 80 },
    { id: 19, title: 'Articles', attempts: 8, correctRate: 88 },
    { id: 20, title: 'Prepositions of Time', attempts: 6, correctRate: 77 },
    { id: 21, title: 'Prepositions of Place', attempts: 5, correctRate: 82 },
    { id: 22, title: 'Reported Speech', attempts: 4, correctRate: 80 },
    { id: 23, title: 'Indirect Questions', attempts: 3, correctRate: 72 },
    { id: 24, title: 'Question Tags', attempts: 7, correctRate: 89 },
    { id: 25, title: 'Quantifiers', attempts: 6, correctRate: 74 },
    { id: 26, title: 'Comparatives and Superlatives', attempts: 5, correctRate: 85 },
    { id: 27, title: 'Adverbs of Frequency', attempts: 8, correctRate: 90 },
    { id: 28, title: 'Passive Voice', attempts: 9, correctRate: 93 },
    { id: 29, title: 'Conditionals (Mixed)', attempts: 2, correctRate: 65 },
    { id: 30, title: 'Relative Pronouns', attempts: 7, correctRate: 80 },
  ];

  // Hàm điều hướng khi người dùng nhấn vào một chủ đề
  const handleTopicClick = (topicId) => {
    navigate(`/grammar/${topicId}`); // Điều hướng đến trang học flashcard của chủ đề
  };

  // Style tối giản với lưới cho các chủ đề
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      maxWidth: '100%',
      margin: '0 auto',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
      textAlign: 'left',
    },
    topicList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '15px',
      marginTop: '20px',
    },
    topicItem: {
      padding: '15px',
      backgroundColor: '#ffffff',
      border: '1px solid #ddd',
      borderRadius: '5px',
      cursor: 'pointer',
      textAlign: 'center',
      fontSize: '18px',
      transition: 'background-color 0.3s ease',
    },
    topicItemHover: {
      backgroundColor: '#4a90e2',
      color: '#fff',
    },
    idNumber: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#4a90e2',
      marginRight: '10px',
    },
    topicTitle: {
      fontSize: '16px',
      fontWeight: 'normal',
    },
    stats: {
      fontSize: '14px',
      color: '#888',
      marginTop: '8px',
    },
    correctRate: {
      fontWeight: 'bold',
      color: '#4a90e2',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Ngữ pháp tiếng Anh</h1>
      <div style={styles.topicList}>
        {grammarTopics.map((topic) => (
          <div
            key={topic.id}
            style={styles.topicItem}
            onClick={() => handleTopicClick(topic.id)}
          >
            <div style={{  alignItems: 'center' }}>
              {/* <span style={styles.idNumber}>{topic.id}</span> */}
              <span>{topic.id}. {topic.title}</span>
            </div>
            <div style={styles.stats}>
              <span>Số lần học: {topic.attempts}</span>
              <br />
              <span>Tỉ lệ đúng: <span style={styles.correctRate}>{topic.correctRate}%</span></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrammarPage;

