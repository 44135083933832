import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


const BookCard = ({ id, title, image, author, description }) => {
  // const navigate = useNavigate();

  const cardStyle = {
    minWidth: '220px',
    maxWidth: '220px',

    marginRight: '15px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    // border: '1px solid #888',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
  };

  // const descriptionStyle = {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  // };

  // const buttonStyle = {
  //   padding: '10px 20px',
  //   border: 'none',
  //   backgroundColor: '#4CAF50',
  //   color: '#fff',
  //   borderRadius: '5px',
  //   cursor: 'pointer',
  //   marginTop: '10px',
  // };

  const cardHoverStyle = {
    transform: 'scale(1.05)',
  };

  const [hover, setHover] = React.useState(false);

  // const handleClick = () => {
  //   navigate(`/ebooks/${id}`);
  // };

  return (
    <div
      style={{ ...cardStyle, ...(hover ? cardHoverStyle : {}) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <NavLink to={`/book/${id}/lessons`}>
          <img src={image} alt={title} style={imageStyle} />
      </NavLink>
     
      {/* <h4>{title}</h4>
      <small>{author}</small>
      <p style={descriptionStyle}>{description}</p>
      <button style={buttonStyle} onClick={handleClick}>
        Read More
      </button> */}
    </div>
  );
};

export default BookCard;
