import React, { useState } from 'react';

const questions = [
  {
    questionText: "It",
    audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3",
    options: [
      { text: "A", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: false },
      { text: "B", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: true },
    ],
  },
  {
    questionText: "What is ... for It?",
    audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3",
    options: [
      { text: "1", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: false },
      { text: "2", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: true },
    ],
  },
  {
    questionText: "What is ... for It?",
    audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3",
    options: [
      { text: "1", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: false },
      { text: "2", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: true },
    ],
  },
  {
    questionText: "What is ... for It?",
    audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3",
    options: [
      { text: "1", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: false },
      { text: "2", audioUrl: "https://dictionary.cambridge.org/vi/media/english/uk_pron/u/uke/ukeas/ukeasil014.mp3", isAnswer: true },
    ],
  }
  // More questions here...
];

const IPATraining = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(null); // "correct" | "incorrect"
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const handleNext = () => {
    setSelectedAnswer(null); // Reset answer when moving to the next question
    setIsAnswered(false); // Reset answered state
    setAnswerStatus(null); // Reset answer status
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleAnswerSelection = (answerIndex) => {
    console.log("--answerIndex|",answerIndex)
    const audio = new Audio(questions[currentQuestionIndex].options[answerIndex].audioUrl);
    audio.play();
    if (isAnswered && answerStatus === 'correct' ) return; // Do not allow selection if already answered

    setSelectedAnswer(answerIndex);
    setIsAnswered(false);
    setAnswerStatus(null);


    
  };

  const handleCheckAnswer = () => {
    if (isAnswered) return; // Prevent checking if already answered
    console.log("--handleCheckAnswer isAnswered |",isAnswered, selectedAnswer)


    const isCorrect = questions[currentQuestionIndex].options[selectedAnswer]?.isAnswer;

    console.log("--handleCheckAnswer isCorrect |",isCorrect)

    if (isCorrect) {
      setCorrectAnswersCount((prev) => prev + 1);
      setAnswerStatus('correct');
      setIsAnswered(true);

      if (questions.length == currentQuestionIndex + 1){
        handleFinish()
      }


    } else {
      setAnswerStatus('incorrect');
      setIsAnswered(true);

    }
  };

  const handleQuestionAudio = () => {
    const audio = new Audio(questions[currentQuestionIndex].audioUrl);
    audio.play();
  };

  const handleFinish = () => {
    setIsFinished(true);
  };

  const handleRestart = () => {
    setIsFinished(false);
    setCorrectAnswersCount(0);
    setCurrentQuestionIndex(0);
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNewLesson = () => {
    setIsFinished(false);
    setCorrectAnswersCount(0);
    setCurrentQuestionIndex(0);
  };

  const buttonStyle = {
    backgroundColor: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div className="question-page" style={{ padding: '20px', fontFamily: 'Arial', maxWidth: '700px', textAlign:'left' }}>
      {!isFinished ? (
        <>
          {/* <p>{`${currentQuestionIndex + 1} of ${questions.length}`}</p> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button style={buttonStyle} >&larr;</button>
            <p>{`${currentQuestionIndex + 1} of ${questions.length}`}</p>
            <button style={buttonStyle}>&rarr;</button>
          </div>

          {/* Hiển thị câu hỏi */}
          <div className="question-card" style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '15px',borderRadius: '5px' }}>
            <p>Select the correct pronunciation for:</p>
            {/* <p>{questions[currentQuestionIndex].questionText}</p>
            <button onClick={handleQuestionAudio} style={{ cursor: 'pointer', color: 'blue' }}>🔊</button> */}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <p style={{ margin: 0 }}>{questions[currentQuestionIndex].questionText}</p>
              <button onClick={handleQuestionAudio} style={{ cursor: 'pointer', color: 'blue', marginLeft: '20px' }}>🔊</button>
            </div>
          </div>

          {/* Hiển thị đáp án */}
          <div className="options" style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            {questions[currentQuestionIndex].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswerSelection(index)}
                style={{
                  // backgroundColor: answerStatus === 'correct' && selectedAnswer === index
                  //   ? '#4CAF50'
                  //   : answerStatus === 'incorrect' && selectedAnswer === index
                  //   ? '#FF7043'
                  //   : selectedAnswer === index ? '#b3d9ff' : '#f1f1f1',
                  backgroundColor:'white',
                  border: selectedAnswer === index && answerStatus === 'correct' ? '2px solid green': selectedAnswer === index && answerStatus === 'incorrect' ? '2px solid red': selectedAnswer === index ? '2px solid blue':'2px solid grey',
                  color: '#000',
                  padding: '10px',
                  margin: '5px 0',
                  borderRadius: '8px',
                  // border: '1px solid',
                  // border: '1px solid blue',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                  width: '100%',
                }}
                // disabled={isAnswered}
              >
                {option.text}
              </button>
            ))}
          </div>

          {/* Nút kiểm tra đáp án */}
          {!isAnswered && selectedAnswer !== null && (
            <div>
              <button
                onClick={handleCheckAnswer}
                style={{
                  backgroundColor: '#007bff',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  cursor: 'pointer',
                  width: '120px',
                }}
              >
                Check Answer
              </button>
            </div>
          )}

          {/* Hiển thị thông báo khi sai */}
          {isAnswered && answerStatus === 'incorrect' && (
            <div style={{ marginTop: '10px', fontSize: '18px', color: 'red' }}>
              Bạn đã chọn sai, nghe và chọn lại!
            </div>
          )}

          {/* Nút chuyển câu (ẩn khi sai và chưa chọn lại) */}
          {isAnswered && answerStatus === 'correct' && (
            <div>
               {/* <div style={{ marginTop: '10px', fontSize: '18px', color: '#198754' }}>
              Good job!
            </div> */}
              <button
                onClick={handleNext}
                style={{
                  backgroundColor: '#198754',
                  color: 'white',
                  padding: '10px',
                  border: 'none',
                  cursor: 'pointer',
                  width: '120px',
                  // marginTop: '10px',
                }}
              >
                Next Question
              </button>
             
            </div>
          )}
        </>
      ) : (
        <>
          <div style={{ fontSize: '18px', color: 'green' }}>
            <span>✅ Bạn đã hoàn thành bài học. </span>
            <span>{`${correctAnswersCount} / ${questions.length} câu đúng`}</span>
          </div>

          {/* Nút lựa chọn sau khi hoàn thành */}
          <div style={{ marginTop: '20px' }}>
            <button
              onClick={handleNewLesson}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                padding: '10px',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              Qua bài mới
            </button>
            <button
              onClick={handleRestart}
              style={{
                backgroundColor: '#28a745',
                color: 'white',
                padding: '10px',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              Học lại
            </button>
            <button
              onClick={handlePrevious}
              style={{
                backgroundColor: '#ffc107',
                color: 'white',
                padding: '10px',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Quay lại bài trước
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default IPATraining;
