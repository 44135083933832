import React, { useState, useEffect ,useCallback} from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useLocation } from "react-router-dom";



const MatchingCard = () => {
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars

    const getData = useCallback(() => {

        console.log("======== MatchingCard | getData ")
        const data = location.state?.data;
    
        if (!Array.isArray(data)) {
        console.error("getData| Data is not in the expected format:", data);
        return [];
        }
    
        const cards = data.map((item) => ({
        front: item.content,
        back: item.translatedContent,
        imageSrc: item.imageSrc,
        englishHint: item.englishHint,
        vietnameseHint: item.vietnameseHint,
        wordType: item.wordType,
        id: item.id,
        }));
        return cards;
        // eslint-disable-next-line no-unused-vars
    }, [location.state?.data]);
    const initialCards = getData()

//   const initialCards = [
//     { id: "1", front: 'Apple', back: 'Quả táo' },
//     { id: "2", front: 'Banana', back: 'Quả chuối' },
//     { id: "3", front: 'Cat', back: 'Con mèo' },
//     { id: "4", front: 'Dog', back: 'Con chó' },
//     { id: "5", front: 'Elephant', back: 'Con voi' },
//     { id: "6", front: 'Fish', back: 'Con cá' },
//     { id: "7", front: 'Grapes', back: 'Quả nho' },
//     { id: "8", front: 'House', back: 'Ngôi nhà' },
//     { id: "9", front: 'Ice', back: 'Đá' },
//     { id: "10", front: 'Juice', back: 'Nước ép' },
//   ];

  const [shuffledFronts, setShuffledFronts] = useState([]);
  const [shuffledBacks, setShuffledBacks] = useState([]);
  const [selectedFront, setSelectedFront] = useState(null);
  const [selectedBack, setSelectedBack] = useState(null);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [shakeEffect, setShakeEffect] = useState('');
  // const navigate = useNavigate();



//   function resetGame() {
//     setShuffledFronts(generateCards(true));
//     setShuffledBacks(generateCards(false));
//     setSelectedFront(null);
//     setSelectedBack(null);
//     setMatchedPairs([]);
//     setShakeEffect('');
//   }

//   function generateCards(isFront) {
//     return initialCards.map(card => ({
//       id: isFront ? `front${card.id}` : `back${card.id}`,
//       content: isFront ? card.front : card.back
//     })).sort(() => Math.random() - 0.5);
//   }

   

    const generateCards = useCallback((isFront) => {
        return initialCards
        .map(card => ({
            id: isFront ? `front${card.id}` : `back${card.id}`,
            content: isFront ? card.front : card.back,
        }))
        .sort(() => Math.random() - 0.5);
    }, [initialCards]);

    const resetGame = useCallback(() => {
        setShuffledFronts(generateCards(true));
        setShuffledBacks(generateCards(false));
        setSelectedFront(null);
        setSelectedBack(null);
        setMatchedPairs([]);
        setShakeEffect('');
    }, [generateCards]);

    useEffect(() => {
        resetGame();
    }, []);

    // resetGame()

    useEffect(() => {
        if (selectedFront && selectedBack) {
        if (selectedFront.id.slice(5) === selectedBack.id.slice(4)) {
            setShakeEffect('correct');
            setTimeout(() => {
            setMatchedPairs(prev => [...prev, selectedFront.id, selectedBack.id]);
            resetSelection();
            }, 1000);
        } else {
            setShakeEffect('incorrect');
            setTimeout(resetSelection, 1000);
        }
        }
    }, [selectedFront, selectedBack]);

  const handleSelect = (card, side) => {
    if (matchedPairs.includes(card.id) || (selectedFront && selectedBack)) return;

    if (side === 'front') {
      setSelectedFront(card);
    } else if (side === 'back') {
      setSelectedBack(card);
    }
  };

  const resetSelection = () => {
    setSelectedFront(null);
    setSelectedBack(null);
    setShakeEffect('');
  };

  const isCardMatched = (card) => matchedPairs.includes(card.id);

  const getCardStyle = (card, isFront) => {
    const isSelected = isFront ? selectedFront?.id === card.id : selectedBack?.id === card.id;
    if (isCardMatched(card)) {
      return { backgroundColor: 'green', animation: 'shakeGreen 1s' };
    }
    if (isSelected) {
      return { border: '2px solid blue' };
    }
    return { backgroundColor: 'white', border: '1px solid #000' };
  };

  const getBackgroundColor = (card) => {
    if (isCardMatched(card)) {
      return 'green';
    }
    if (shakeEffect === 'incorrect' && (selectedFront?.id === card.id || selectedBack?.id === card.id)) {
      return 'red';
    }
    return 'white';
  };

  const handleRestart = () => {
    resetGame();
  };

  const handleGoToList = () => {
    // navigate("/list");
  };

  const allCardsMatched = matchedPairs.length === initialCards.length * 2;
  console.log("matchedPairs.length|",matchedPairs.length)
  console.log("initialCards.length|",initialCards.length)

  return (
    <div style={containerStyle}>
      {allCardsMatched ? (
        <div style={completionStyle}>
          <h2>Bạn đã hoàn thành bài học!</h2>
          {/* <button onClick={handleRestart}>Restart</button>
          <button onClick={handleGoToList}>Lesson List</button> */}
         <div style={buttonContainerStyle}>
            <Button type="primary" block style={buttonStyle} onClick={handleRestart}>
              Restart
            </Button>
            <Button type="primary" block style={buttonStyle} onClick={handleGoToList}>
              Lesson List
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={rowStyle}>
            {shuffledFronts
              .filter((card) => !matchedPairs.includes(card.id))
              .map((card) => (
                <div
                  key={card.id}
                  onClick={() => handleSelect(card, 'front')}
                  style={{
                    ...cardStyle,
                    backgroundColor: getBackgroundColor(card, true),
                    border: getCardStyle(card, true).border,
                    animation: (selectedFront?.id === card.id) && shakeEffect
                      ? `${shakeEffect === 'correct' ? 'shakeGreen' : 'shakeRed'} 0.5s`
                      : 'none',
                  }}
                >
                  {card.content}
                </div>
              ))}
          </div>
          <div style={rowStyle}>
            {shuffledBacks
              .filter((card) => !matchedPairs.includes(card.id))
              .map((card) => (
                <div
                  key={card.id}
                  onClick={() => handleSelect(card, 'back')}
                  style={{
                    ...cardStyle,
                    backgroundColor: getBackgroundColor(card, true),
                    border: getCardStyle(card, false).border,
                    animation: (selectedBack?.id === card.id) && shakeEffect
                      ? `${shakeEffect === 'correct' ? 'shakeGreen' : 'shakeRed'} 0.5s`
                      : 'none',
                  }}
                >
                  {card.content}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

// Styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center', 
  alignItems: 'flex-start',
  gap: '20px',
  padding: '20px',
};

const rowStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};


const cardStyle = {
  width: '250px',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '8px',
  textAlign: 'center',
  transition: 'background-color 0.3s, border-color 0.3s',
};

const completionStyle = {
  textAlign: 'center',
};

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',          // Adds spacing between the buttons
  width: '200px',       // Ensures both buttons are the same width
  margin: '0 auto',     // Centers the button container
};

const buttonStyle = {
  height: '40px',       // Sets a consistent height for both buttons
};

export default MatchingCard;