import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import axios from 'axios';



const BookLessonList = () => {
  const { id } = useParams(); // Lấy id từ URL
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  // Hàm gọi API
  const fetchBookLessons = async (id) => {
    try {
      console.log("===id |",id)
      const storedAccessToken = localStorage.getItem('access_token');
      const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/api/v1/book-lesson/lessons?book_id=${id}`, {
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`
        }
      });
      console.log("===response|",response.data)
      // console.log("===response Reading|",response.data.reading_lessons)

      setLessons(response.data); // Lưu dữ liệu trả về vào state
      setLoading(false);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  // Gọi API khi component được render
  useEffect(() => {
    fetchBookLessons(id);
  }, [id]);

  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    sections: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    section: {
      width: '48%',
    },
    sectionTitle: {
      color: '#4a90e2',
      fontSize: '20px',
      marginBottom: '10px',
    },
    lessonItem: {
      margin: '10px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
    },
    button: {
      backgroundColor: '#4a90e2',
      color: '#fff',
      border: 'none',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  const handleLessonClick = (bookId,lessonId) => {
    console.log(`Navigating to lesson ${lessonId}`);
    navigate(`/book/${bookId}/${lessonId}`);

  };

  if (loading) {
    return <div style={styles.container}>Đang tải dữ liệu...</div>;
  }

  if (lessons.reading_lessons == null || lessons.listening_lessons  == null ) {
    return <div style={styles.container}>Not Found!</div>;
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>IELTS Cambridge Book</h1>
      <div style={styles.sections}>
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>📖 Reading</h2>
          <ul>
            {lessons.reading_lessons
              // .filter((lesson) => lesson.type === 'reading')
              .map((lesson) => (
                <li key={lesson.id} style={styles.lessonItem}>
                  <span>{lesson.title}</span>
                  <button
                    style={styles.button}
                    onClick={() => handleLessonClick(id,lesson.id)}
                  >
                    Bắt đầu học
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>🎧 Listening</h2>
          <ul>
            {lessons.listening_lessons
              .map((lesson) => (
                <li key={lesson.id} style={styles.lessonItem}>
                  <span>{lesson.title}</span>
                  <button
                    style={styles.button}
                    onClick={() => handleLessonClick(id,lesson.id)}
                  >
                    Bắt đầu học
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BookLessonList;
