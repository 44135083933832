import React, { useState } from 'react';

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const [voice, setVoice] = useState('en-US');
  const [isSpeaking, setIsSpeaking] = useState(false);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    setVoice(e.target.value);
  };

  const handleSpeak = () => {
    if (!text) {
      alert('Vui lòng nhập nội dung để đọc.');
      return;
    }

    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);

    // Tìm giọng nói phù hợp dựa trên lựa chọn của người dùng
    const selectedVoice = synth.getVoices().find((v) => v.lang === voice);
    if (selectedVoice) {
      utterance.voice = selectedVoice;
    }

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);

    synth.speak(utterance);
  };

  const handleStop = () => {
    window.speechSynthesis.cancel();
    setIsSpeaking(false);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h2>Chuyển Văn Bản Thành Giọng Nói</h2>

      <textarea
        value={text}
        onChange={handleTextChange}
        placeholder="Nhập văn bản tại đây..."
        rows="5"
        style={{ width: '100%', marginBottom: '10px', padding: '10px', fontSize: '16px' }}
      />

      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="voiceSelect" style={{ marginRight: '10px' }}>Chọn giọng nói:</label>
        <select id="voiceSelect" value={voice} onChange={handleVoiceChange}>
          <option value="en-US">Giọng Anh Mỹ (en-US)</option>
          <option value="en-GB">Giọng Anh Anh (en-GB)</option>
        </select>
      </div>

      <div>
        <button
          onClick={handleSpeak}
          disabled={isSpeaking}
          style={{
            padding: '10px 20px',
            marginRight: '10px',
            backgroundColor: isSpeaking ? '#ccc' : '#4CAF50',
            color: '#fff',
            border: 'none',
            cursor: isSpeaking ? 'not-allowed' : 'pointer',
          }}
        >
          {isSpeaking ? 'Đang đọc...' : 'Đọc'}
        </button>
        <button
          onClick={handleStop}
          disabled={!isSpeaking}
          style={{
            padding: '10px 20px',
            backgroundColor: '#f44336',
            color: '#fff',
            border: 'none',
            cursor: isSpeaking ? 'pointer' : 'not-allowed',
          }}
        >
          Dừng
        </button>
      </div>
    </div>
  );
};

export default TextToSpeech;
