// import React from 'react';
// import { Button, Dropdown, Menu } from 'antd';
// import { NavLink } from 'react-router-dom';
// import Logo from './Logo';

// const AppHeader = ({ user, onLogout }) => {
//     const handleMenuClick = (e) => {
//         if (e.key === 'logout') {
//             onLogout();

//         }
//     };

//     const menu = (
//         <Menu onClick={handleMenuClick}>
//             <Menu.Item key="progress">Your Progress</Menu.Item>
//             <Menu.Item key="user-type">Free User</Menu.Item>
//             <Menu.Item key="logout">Logout</Menu.Item>
//         </Menu>
//     );

//     return (
//         <>
//             <header style={headerStyle}>
//                 <div style={logoStyle}>
//                     <NavLink to="/"> {/* Sử dụng NavLink cho logo */}
//                         <Logo />
//                     </NavLink>
//                 </div>
//                 <nav style={menuStyle}>
//                     {/* <NavLink 
//                         to="/" 
//                         style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
//                     >
//                         Home
//                     </NavLink> */}
//                     {/* <NavLink 
//                         to="/ai-card-creator" 
//                         style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
//                     >
//                         AI Cards
//                     </NavLink> */}
//                     <NavLink 
//                         to="/orders" 
//                         style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
//                     >
//                         Pricing
//                     </NavLink>
//                     <NavLink 
//                         to="/about" 
//                         style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
//                     >
//                         About Us
//                     </NavLink>
//                     {user ? (
//                         <div style={userAreaStyle}>
//                             <Dropdown 
//                                 // menu={menu} 
//                                 overlay ={menu} 
//                                 trigger={['click']}
//                             >
//                                 {/* <Button 
//                                     style={buttonStyle} 
//                                     onClick={e => e.preventDefault()} // Ngăn chặn sự kiện mặc định
//                                 > */}
//                                     <img 
//                                         src={user.picture || 'default-profile-pic.png'} // Thay đổi hình ảnh mặc định nếu cần
//                                         alt="User Icon" 
//                                         style={{ width: '30px', height: '30px', borderRadius: '50%' }} // Biểu tượng người dùng
//                                     />
//                                 {/* </Button> */}
//                             </Dropdown>
//                             <span style={userNameStyle}>{user.name}</span>
//                         </div>
//                     ) : (
//                         <NavLink to="/login">
//                             <Button type="primary" style={buttonStyle}>Login</Button>
//                         </NavLink>
//                     )}
//                 </nav>
//             </header>
//             {/* Phần này giúp tạo khoảng trống bù lại chiều cao của header */}
//             <div style={{ paddingTop: '64px' }} />
//         </>
//     );
// };

// // CSS styles
// const headerStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '0 20px',
//     backgroundColor: 'white', // nền trắng
//     height: '64px',
//     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // bóng mờ nhẹ
//     position: 'fixed', // cố định header
//     top: 0, // nằm ở đầu trang
//     width: '100%', // chiếm toàn bộ chiều ngang màn hình
//     zIndex: 1000, // đảm bảo luôn ở trên các phần tử khác
// };

// const logoStyle = {
//     color: '#1890ff', // màu xanh cho logo
//     fontSize: '24px',
//     fontWeight: 'bold',
//     flex: '0 0 auto',
// };

// const menuStyle = {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     alignItems: 'center',
//     flex: '1',
// };

// const menuItemStyle = {
//     color: '#333', // chữ đen nhạt
//     margin: '0 15px',
//     textDecoration: 'none',
//     fontSize: '16px',
//     position: 'relative', // cần để có thể thêm hiệu ứng gạch
// };

// const activeMenuItemStyle = {
//     borderBottom: '2px solid black', // Gạch đen bên dưới mục đang được chọn
// };

// const userAreaStyle = {
//     display: 'flex',
//     alignItems: 'center',
// };

// const userNameStyle = {
//     color: '#333', // chữ đen nhạt
//     marginRight: '15px',
//     fontSize: '16px',
// };

// const buttonStyle = {
//     backgroundColor: '#1890ff', // nút màu xanh
//     borderColor: '#1890ff',
//     color: 'white', // chữ màu trắng
// };

// export default AppHeader;


import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Logo from './Logo'; // Keep this for the left logo if needed

const AppHeader = ({ user, onLogout }) => {
    const handleMenuClick = (e) => {
        console.log("==user.avatar_url|",user.avatar_url)
        if (e.key === 'logout') {
            onLogout();
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="progress">Your Progress</Menu.Item>
            <Menu.Item key="user-type">Free User</Menu.Item>
            <Menu.Item key="logout">Logout</Menu.Item>
        </Menu>
    );

    return (
        
            <div style={headerStyle}>
                
                <div style={rightLogoStyle}>
                <NavLink to="/"> 
                    <img
                        src="/images/logo2.webp" // Provide the correct path to the .webp image
                        alt="Logo"
                        style={imageLogoStyle}
                    />
                </NavLink> 
                </div>
                <div style={logoStyle}>
                    {/* <NavLink to="/">  */}
                    {/* <a style={{ fontSize: '24px',  textDecoration: 'None', }}>
                        FlashiGo
                    </a> */}
                          <Link to="/" style={{ textDecoration: 'none' , color :'#1890ff'}} >FLASHIGO</Link>

                       
                        {/* <Logo /> */}
                    {/* </NavLink> */}
                     {/* <Logo /> */}
                </div>
                {/* <div  
                > */}
                
                <nav  
                style={menuStyle}>
                    {/* <NavLink 
                        to="/orders" 
                        style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
                    >
                        Pricing
                    </NavLink> */}
                    <NavLink 
                        to="/about" 
                        style={({ isActive }) => ({ ...menuItemStyle, ...(isActive ? activeMenuItemStyle : {}) })}
                    >
                        About Us
                    </NavLink>
                    {user ? (
                        <div style={userAreaStyle}>
                            <Dropdown 
                                overlay={menu} 
                                trigger={['click']}
                            >
                                <img 
                                    src={user.avatar_url || 'default-profile-pic.png'} 
                                    alt="User Icon" 
                                    style={{ width: '30px', height: '30px', borderRadius: '50%' }} 
                                />
                            </Dropdown>
                            <span style={userNameStyle}>{user.name}</span>
                        </div>
                    ) : (
                        <NavLink to="/login">
                            <Button type="primary" style={buttonStyle}>Login</Button>
                        </NavLink>
                    )}
                </nav>
                {/* </div> */}
            </div>
        
      
    );
};

// CSS styles
const headerStyle = {
    // paddingRight:'20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '0 20px',
    backgroundColor: 'white',
    height: '52px',
    // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(0, 0, 0, 0.1)', // Thêm border
    // position: 'fixed',
    // top: 0,
    // width: '100%',
    // zIndex: 1000,
};

const logoStyle = {
    color: '#1890ff',
    fontSize: '24px',
    fontWeight: 'bold',
    flex: '0 0 auto',
    marginLeft: '16px', // Align the image to the right


};

const rightLogoStyle = {
    flex: '0 0 auto',
    marginLeft: '36px', // Align the image to the right
    
};

const imageLogoStyle = {
    width: '24px', // Adjust the size as needed
    height: '24px', // Adjust the size as needed

};

const menuStyle = {
    marginRight:'36px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1',
};

const menuItemStyle = {
    color:'black',
    // color: '#333',
    marginRight: '16px',
    textDecoration: 'none',
    fontSize: '16px',
};

const activeMenuItemStyle = {
    borderBottom: '1px solid black',
};

const userAreaStyle = {
    display: 'flex',
    alignItems: 'center',
};

const userNameStyle = {
    color: '#333',
    marginRight: '15px',
    fontSize: '16px',
};

const buttonStyle = {
    backgroundColor: '#1890ff',
    borderColor: '#1890ff',
    color: 'white',
};

export default AppHeader;
