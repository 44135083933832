import React from 'react';
import CardCarousel from './CardCarousel'; // Import CardCarousel component
import axios from 'axios';
import { useEffect, useState } from 'react';

const BookPage = () => {
  const [books, setBooks] = useState([]); // State để lưu danh sách books

  // Hàm gọi API
  const fetchBooks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/books`);
      setBooks(response.data.books); // Lưu dữ liệu trả về vào state
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  // Gọi API khi component được render
  useEffect(() => {
    fetchBooks();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} />
      <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} />
      <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} />

      {/* <CardCarousel type = "card" title="English Vocabulary in Use" data={flashcardSets.nouns} />
      <CardCarousel type = "card" title="Vocabulary Building" data={flashcardSets.verbs} />
      <CardCarousel type = "card" title="Tính từ" data={flashcardSets.verbs} />
      <CardCarousel type = "card" title="Trạng từ" data={flashcardSets.verbs} /> */}
    </div>
  );
};

export default BookPage;
