import { useState, useEffect } from 'react';
import { googleLogout } from '@react-oauth/google';


const useAuth = () => {
    console.log("===useAuth start|");

    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    // Hàm kiểm tra hạn của access_token
    const isTokenExpired = (token) => {
        const payload = JSON.parse(atob(token.split('.')[1])); // Decode phần payload của JWT
        const expiration = payload.exp * 1000; // exp là thời gian hết hạn (giây), chuyển sang ms
        return Date.now() > expiration;
    };

    const refreshAccessToken = async (refresh_token) => {
        console.log('----Auth refreshAccessToken|');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/auth/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh_token }),
            });
            const data = await response.json();

            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                setAccessToken(data.access_token);
                // setUser(data.user); // Phục hồi trạng thái người dùng

            } else {
                logout(); // Nếu không thể làm mới token, logout
            }
        } catch (error) {
            console.error('Failed to refresh token:', error);
            logout();
        }
    };

    // Lấy dữ liệu từ localStorage khi hook được khởi tạo
    useEffect(() => {
        console.log('----Auth useEffect|');

        const storedUser = localStorage.getItem('user');
        const storedAccessToken = localStorage.getItem('access_token');
        const storedRefreshToken = localStorage.getItem('refresh_token');

        if (storedUser && storedAccessToken) {
            if (!isTokenExpired(storedAccessToken)) {
                setUser(JSON.parse(storedUser)); // Phục hồi trạng thái người dùng
                setAccessToken(storedAccessToken);
            } else if (storedRefreshToken) {
                // Nếu access_token hết hạn, thử làm mới nó bằng refresh_token
                refreshAccessToken(storedRefreshToken);
                setUser(JSON.parse(storedUser)); // Phục hồi trạng thái người dùng
            } else {
                logout(); // Nếu không có refresh_token, logout
            }
        }
    }, []);

    const login = (userData, access_token, refresh_token) => {
        console.log('----check userData|',userData);
        console.log('----check access_token|',access_token);

        console.log('----check refresh_token|',refresh_token);


        setUser(userData); // Cập nhật trạng thái người dùng
        setAccessToken(access_token);
        localStorage.setItem('user', JSON.stringify(userData)); // Lưu người dùng vào localStorage
        localStorage.setItem('access_token', access_token); // Lưu access_token vào localStorage
        localStorage.setItem('refresh_token', refresh_token); // Lưu refresh_token vào localStorage
    };

    const logout = () => {
        setUser(null); // Xóa trạng thái người dùng
        setAccessToken(null);
        localStorage.removeItem('user'); // Xóa người dùng khỏi localStorage
        localStorage.removeItem('access_token'); // Xóa access_token khỏi localStorage
        localStorage.removeItem('refresh_token'); // Xóa refresh_token khỏi localStorage
        googleLogout(); // Đảm bảo người dùng đã logout khỏi Google
        // google.accounts.id.disableAutoSelect(); //
        // if (window.google && window.google.accounts && window.google.accounts.id) {
        //     window.google.accounts.id.disableAutoSelect();
        //   } else {
        //     console.error("Google Identity Services chưa được nạp.");
        //   }
    };

    return { user, accessToken, login, logout, refreshAccessToken, isTokenExpired };
};

export default useAuth;
