import React, { useState, useEffect } from 'react';

const PomodoroTimer = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [timeLeft, setTimeLeft] = useState(25 * 60); // 25 minutes in seconds
  const [isBreak, setIsBreak] = useState(false);
  const [completedPomodoros, setCompletedPomodoros] = useState(0);

  useEffect(() => {
    let interval;

    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      if (isBreak) {
        // Break ended, start next Pomodoro
        setIsBreak(false);
        setTimeLeft(25 * 60);
        setCompletedPomodoros(completedPomodoros + 1);
      } else {
        // Pomodoro ended, start break
        setIsBreak(true);
        setTimeLeft(5 * 60); // 5 minutes break
      }
    }

    return () => clearInterval(interval);
  }, [isRunning, timeLeft, isBreak, completedPomodoros]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const startStopPomodoro = () => {
    setIsRunning((prevState) => !prevState);
  };

  const resetPomodoro = () => {
    setIsRunning(false);
    setTimeLeft(25 * 60);
    setIsBreak(false);
  };

  return (
    <div className="pomodoro-timer" style={styles.container}>
      <h2 style={styles.header}>Pomodoro Timer</h2>
      <div style={styles.timerDisplay}>
        {formatTime(timeLeft)}
      </div>
      <div style={styles.buttonContainer}>
        <button 
          onClick={startStopPomodoro} 
          style={isRunning ? styles.stopButton : styles.startButton}>
          {isRunning ? 'Pause' : 'Start'}
        </button>
      </div>
      <div style={styles.buttonContainer}>
        <button 
          onClick={resetPomodoro} 
          style={styles.resetButton}>
          Reset
        </button>
      </div>
      <div style={styles.stats}>
        <h3>Pomodoros Completed: {completedPomodoros}</h3>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    width: '300px',
    margin: '50px auto',
  },
  header: {
    fontSize: '24px',
    color: '#333',
    marginBottom: '20px',
  },
  timerDisplay: {
    fontSize: '48px',
    marginBottom: '20px',
    fontWeight: 'bold',
    color: '#e74c3c',
  },
  buttonContainer: {
    marginBottom: '10px',
  },
  startButton: {
    padding: '10px 20px',
    backgroundColor: '#2ecc71',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  stopButton: {
    padding: '10px 20px',
    backgroundColor: '#e74c3c',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  resetButton: {
    padding: '10px 20px',
    backgroundColor: '#3498db',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  stats: {
    marginTop: '20px',
    fontSize: '18px',
    color: '#333',
  },
};

export default PomodoroTimer;
