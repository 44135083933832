import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { FaRegClone, FaUser } from 'react-icons/fa'; // Import icon components

const CardSet = ({ id, title, description, topic, numberOfCards, subscribedUsers }) => {
  const navigate = useNavigate(); 

  const cardStyle = {
    minWidth: '220px',
    maxWidth: '220px',
    marginRight: '15px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    border: '1px solid #888',

  };

  const descriptionStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const iconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    color: '#888',
  };

  const iconTextStyle = {
    marginLeft: '5px',
  };

  // const buttonStyle = {
  //   padding: '10px 20px',
  //   border: 'none',
  //   backgroundColor: '#4CAF50',
  //   color: '#fff',
  //   borderRadius: '5px',
  //   cursor: 'pointer',
  // };

  const cardHoverStyle = {
    transform: 'scale(1.05)',
  };

  const [hover, setHover] = React.useState(false);

  const handleClick = () => {
    navigate(`/flashcards/${id}`);
  };

  return (
    <div
      onClick={handleClick}
      style={{ ...cardStyle, ...(hover ? cardHoverStyle : {}) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <h4>{title}</h4>

      {/* Line with icons */}
      <div style={iconStyle}>
        <FaRegClone /> 
        <span style={iconTextStyle}>{numberOfCards} 100 cards</span>
        <FaUser style={{ marginLeft: '15px' }} /> 
        <span style={iconTextStyle}>{subscribedUsers} users</span>
      </div>

      <small>{topic}</small>
      <p style={descriptionStyle}>{description}</p>

      {/* Study Button */}
      {/* <button style={buttonStyle} onClick={handleClick}>
        Study
      </button> */}
    </div>
  );
};

export default CardSet;
