import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


import { Breadcrumb } from 'antd';

const WrapperBreadcrumb = ({ location }) => {

  console.log("--- WrapperBreadcrumb location.pathname |", location.pathname);

  const navigate = useNavigate();

  const getBreadcrumbItems = (pathname) => {
    if (!pathname) {
      pathname = ''
    }
    console.log("--- getBreadcrumbItem pathname |", pathname);
  
    var paths = pathname.split('>').filter(Boolean); // Tách các phần của đường dẫn
    console.log("--- getBreadcrumbItem paths|", paths);

    if (paths && paths.length ==1 ){
      paths=[]
    }



    const color = '#1890ff'
    const colorTitle = '#212529BF'
    const breadcrumbItems = paths.map((path, index) => (

      index == paths.length-1 ?
      
      {
      key: path,
      title: (
        <span
          // onClick={() => navigate('/' + paths.slice(0, index + 1).join('/'))}
          style={{ 
            // cursor: 'pointer', 
            color: colorTitle }}
        >
          {path.charAt(0).toUpperCase() + path.slice(1)}
        </span>
      ),
    }:{
      key: path,
      title: (
        <span
          onClick={() => navigate('/' + paths.slice(0, index + 1).join('/'))}
          style={{ cursor: 'pointer', color: color, textDecoration: 'underline' }}
        >
          {path.charAt(0).toUpperCase() + path.slice(1)}
        </span>
      ),
    }
  
  ));

    console.log("--- breadcrumbItems |", breadcrumbItems);
  
    return breadcrumbItems;
  };


  const urlTitleRef = useRef(""); // Không trigger re-render
  const [, forceRender] = useState(false); // Trigger re-render khi cần

  useEffect(() => {
    const fetchURLTitle = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVICE_URL}/api/v1/ui/url/title?url=${location.pathname}`
        );
        urlTitleRef.current = response.data.url_title;
        console.log("url:", location.pathname);

        console.log("✅ API trả về:", urlTitleRef.current);
        forceRender((prev) => !prev); // Cập nhật UI mà không ảnh hưởng đến state chính
      } catch (error) {
        console.error("❌ Lỗi khi gọi API:", error);
      }
    };

    fetchURLTitle();
  }, [location.pathname]);


  return (
      <div style={{ backgroundColor: "white", padding: "10px", height:"40px" }}>
        <Breadcrumb
          separator=">"
          style={{
            paddingLeft: "10px",
            // paddingTop: "10px",
            fontSize: "14px",
          }}
          items={getBreadcrumbItems(urlTitleRef.current)}
        />
      </div>
    );
  };
  
  export default WrapperBreadcrumb;
  