import React from 'react';

const Orders = () => {
    const subscriptionPackages = [
        // { id: 1, name: 'VIP 1 tháng', price: '100,000 VND', duration: '1 tháng' },
        { id: 2, name: 'VIP 3 tháng', price: '250,000 VND', duration: '3 tháng' },
        { id: 3, name: 'VIP 6 tháng', price: '450,000 VND', duration: '6 tháng' },
        { id: 4, name: 'VIP 1 năm', price: '800,000 VND', duration: '1 năm' },
        { id: 5, name: 'VIP 2 năm', price: '1,500,000 VND', duration: '2 năm' },
        // { id: 6, name: 'VIP 3 năm', price: '2,000,000 VND', duration: '3 năm' },
    ];

    const handleSubscribe = (packageName) => {
        alert(`You have chosen the ${packageName} package.`);
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Subscription Packages</h2>
            <p style={styles.description}>Here you can view and manage your subscription packages.</p>

            <div style={styles.gridContainer}>
                {subscriptionPackages.map((pkg) => (
                    <div key={pkg.id} style={styles.card}>
                        <h3 style={styles.packageName}>{pkg.name}</h3>
                        <p style={styles.price}>{pkg.price}</p>
                        {/* <p style={styles.duration}>{pkg.duration}</p> */}
                        <button
                            style={styles.buyButton}
                            onClick={() => handleSubscribe(pkg.name)}
                        >
                            Mua ngay
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center', // Canh giữa nội dung
    },
    title: {
        fontSize: '32px',
        color: '#333',
        marginBottom: '20px',
    },
    description: {
        fontSize: '16px',
        marginBottom: '20px',
        color: '#555',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Hiển thị theo dạng lưới
        gap: '20px',
        justifyContent: 'center',
    },
    card: {
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    packageName: {
        fontSize: '20px',
        color: '#333',
        marginBottom: '10px',
    },
    price: {
        fontSize: '24px',
        fontWeight: 'bold',
        // color: '#ff5722', // Làm nổi bật số tiền
        marginBottom: '10px',
    },
    duration: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px',
    },
    buyButton: {
        backgroundColor: '#007e75', // Màu xanh tiền
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '16px',
    },
};

export default Orders;
