import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useEffect, useState } from 'react';


const UnitPage = () => {
  const [ipas, setIPAs] = useState([]); // State để lưu danh sách books

  // Hàm gọi API
  const fetchIPAs = async () => {
    try {
      const storedAccessToken = localStorage.getItem('access_token');
      const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/api/v1/ipa/all`,{
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`
        }
      });
      console.log("===ipas|",response.data.ipas)
      setIPAs(response.data.ipas); // Lưu dữ liệu trả về vào state
    } catch (error) {
      console.error('Error fetching ipas:', error);
    }
  };

  // Gọi API khi component được render
  useEffect(() => {
    fetchIPAs();
  }, []);


  const { topicId } = useParams(); // Lấy topicId từ URL
  const navigate = useNavigate();

    // Hàm điều hướng khi người dùng nhấn vào một chủ đề
  const handleTopicClick = (topicId) => {
    navigate(`/dictation/ipa/${topicId}`); // Điều hướng đến trang học flashcard của chủ đề
  };

  // Dữ liệu danh sách các unit cho một chủ đề (có thể thay bằng API call thực tế)
  const unitData = [
    { unit: 1, title: 'Introduction to IPA', attempts: 3, correctRate: 85 },
    { unit: 2, title: 'Vowel Sounds', attempts: 5, correctRate: 90 },
    { unit: 3, title: 'Consonant Sounds', attempts: 4, correctRate: 88 },
    { unit: 4, title: 'Stress and Intonation', attempts: 6, correctRate: 92 },
    { unit: 5, title: 'Advanced Practice', attempts: 2, correctRate: 80 },
  ];

  // Style đơn giản cho trang con
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      maxWidth: '100%',
      margin: '0 auto',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
      textAlign: 'center',
    },
    unitList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    unitItem: {
      padding: '10px',
      backgroundColor: '#ffffff',
      border: '1px solid #ddd',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s ease',
      cursor: 'pointer',
    },
    unitItemHover: {
      transform: 'scale(1.02)',
    },
    unitHeader: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#4a90e2',
      fontFamily:"system-ui"
    },
    stats: {
      fontSize: '12px',
      color: '#888',
      marginTop: '8px',
    },
    correctRate: {
      fontWeight: 'bold',
      color: '#4a90e2',
    },
  };

  return (
    <div style={styles.container}>
      {/* <h1 style={styles.title}>Units in Topic {topicId}</h1> */}
      <div style={styles.unitList}>
        {ipas.map((ipa) => (
          <div key={ipa.id} style={styles.unitItem}  onClick={() => handleTopicClick(ipa.lesson_order)}
           >
            <div style={styles.unitHeader}>
              Unit {ipa.lesson_order}: {ipa.lesson_name}  {ipa.description} 
            </div>
            <div style={styles.stats}>
              <span>Số lần học: 88</span>
              <br />
              <span>
                Tỉ lệ đúng: <span style={styles.correctRate}>80%</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnitPage;
