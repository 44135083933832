import React, { useState } from 'react';

const BookSummaryPage = ({ title, summary, chapterSummaries, quizQuestions }) => {
  const [isQuizVisible, setIsQuizVisible] = useState(false);

  const toggleQuiz = () => {
    setIsQuizVisible(!isQuizVisible);
  };

  const styles = {
    bookSummaryPage: {
      fontFamily: 'Arial, sans-serif',
      margin: '20px',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    bookTitle: {
      fontSize: '2rem',
      color: '#333',
      marginBottom: '10px',
    },
    bookSummary: {
      fontSize: '1rem',
      color: '#555',
      marginBottom: '20px',
    },
    chapterTitle: {
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '10px',
    },
    chapterList: {
      listStyleType: 'none',
      paddingLeft: '0',
    },
    chapterItem: {
      marginBottom: '15px',
    },
    chapterName: {
      fontSize: '1.2rem',
      color: '#2c3e50',
    },
    chapterSummary: {
      fontSize: '1rem',
      color: '#555',
    },
    toggleQuizBtn: {
      backgroundColor: '#3498db',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    toggleQuizBtnHover: {
      backgroundColor: '#2980b9',
    },
    quiz: {
      marginTop: '20px',
    },
    quizTitle: {
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '15px',
    },
    question: {
      marginBottom: '20px',
    },
    questionText: {
      fontSize: '1.1rem',
      color: '#333',
    },
    optionsList: {
      listStyleType: 'none',
      paddingLeft: '0',
    },
    optionItem: {
      fontSize: '1rem',
      color: '#555',
      marginBottom: '10px',
    },
    optionInput: {
      marginRight: '10px',
    }
  };

  return (
    <div style={styles.bookSummaryPage}>
      <h2 style={styles.bookTitle}>{title}</h2>
      <p style={styles.bookSummary}>{summary}</p>

      <h3 style={styles.chapterTitle}>Chapter Summaries</h3>
      <ul style={styles.chapterList}>
        {chapterSummaries.map((chapter, index) => (
          <li key={index} style={styles.chapterItem}>
            <h4 style={styles.chapterName}>Chapter {index + 1}: {chapter.title}</h4>
            <p style={styles.chapterSummary}>{chapter.summary}</p>
          </li>
        ))}
      </ul>

      <button
        style={isQuizVisible ? { ...styles.toggleQuizBtn, ...styles.toggleQuizBtnHover } : styles.toggleQuizBtn}
        onClick={toggleQuiz}
      >
        {isQuizVisible ? 'Hide Quiz' : 'Show Quiz'}
      </button>

      {isQuizVisible && (
        <div style={styles.quiz}>
          <h3 style={styles.quizTitle}>Quiz</h3>
          {quizQuestions.map((question, index) => (
            <div key={index} style={styles.question}>
              <p style={styles.questionText}>{question.text}</p>
              <ul style={styles.optionsList}>
                {question.options.map((option, optionIndex) => (
                  <li key={optionIndex} style={styles.optionItem}>
                    <input type="radio" name={`question-${index}`} style={styles.optionInput} /> {option}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Mock data
const mockData = {
  title: 'Learning React: A Guide for Beginners',
  summary: 'This book covers the fundamental concepts of React and how to build modern web applications using React.',
  chapterSummaries: [
    { title: 'Introduction to React', summary: 'Learn the basics of React, JSX, and the core concepts of components and state.' },
    { title: 'State and Props', summary: 'Explore the concepts of state and props and how they help build dynamic user interfaces.' },
    { title: 'React Router', summary: 'Learn how to implement routing in React applications using React Router.' },
    { title: 'React Hooks', summary: 'Understand how hooks simplify state and side-effects management in React.' },
  ],
  quizQuestions: [
    { text: 'What is JSX?', options: ['JavaScript Syntax Extension', 'A JavaScript function', 'A React component', 'A CSS framework'] },
    { text: 'What does useState do in React?', options: ['Manages state in functional components', 'Sets the component title', 'Handles event binding', 'Renders components'] },
    { text: 'Which package is used for routing in React?', options: ['react-router', 'react-route', 'router-react', 'react-navigation'] },
  ],
};

export default function App() {
  return (
    <BookSummaryPage 
      title={mockData.title} 
      summary={mockData.summary} 
      chapterSummaries={mockData.chapterSummaries} 
      quizQuestions={mockData.quizQuestions}
    />
  );
}
