import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ReadingLesson = () => {
  const { bookId, lessonId } = useParams(); // Lấy id từ URL

  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);

  // const [mode, setMode] = useState(null);

  const [showBilingual, setShowBilingual] = useState(true); // Hiển thị song ngữ hay không

  // Hàm gọi API
  const fetchBookLesson = async (book_id,lesson_id) => {

    try {
      const storedAccessToken = localStorage.getItem('access_token');

      const response = await axios.get(`${process.env.REACT_APP_API_SERVICE_URL}/api/v1/book-lesson/lesson?book_id=${book_id}&lesson_id=${lesson_id}`,{
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`
        }
      }
      );
      setLesson(response.data); // Lưu dữ liệu trả về vào state
      setLoading(false);
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  // Gọi API khi component được render
  useEffect(() => {
    fetchBookLesson(bookId, lessonId );
  }, [bookId, lessonId]);

  const handleModeSelect = (selectedMode) => {
    // setMode(selectedMode);
  };

  const handleShowBilingualChange = (event) => {
    setShowBilingual(event.target.checked);
  };

  if (loading) {
    return <div style={styles.container}>Đang tải dữ liệu...</div>;
  }

  return (
    <div style={styles.container}>
      {/* Hiển thị tiêu đề */}
      <h1 style={styles.title}>{lesson.title}</h1>
      <p style={styles.titleVi}>{lesson.title_vi}</p>
      
      <div style={styles.checkboxContainer}>
        <label>
          <input 
            type="checkbox" 
            checked={showBilingual} 
            onChange={handleShowBilingualChange} 
          />
          Song ngữ
        </label>
      </div>

      <div style={styles.modeSelector}>
        <button style={styles.button} onClick={() => handleModeSelect('meaning')}>
          Học Từ Mới
        </button>
        <button style={styles.button} onClick={() => handleModeSelect('vocabulary')}>
          Học Câu
        </button>
        <button style={styles.button} onClick={() => handleModeSelect('grammar')}>
          Luyện Viết
        </button>
      </div>

      {/* Hiển thị nội dung */}
      <div style={styles.content}>
        {lesson.paragraphs.slice(1).map((paragraph, index) => (
          <div key={index} style={styles.paragraph}>
            {showBilingual && (
              <>
                <p style={styles.textEn}>{paragraph.en}</p>
                <p style={styles.textVi}>{paragraph.vi}</p>
              </>
            )}

            {!showBilingual && (
              <p style={styles.textEn}>{paragraph.en}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  titleVi: {
    fontSize: '18px',
    fontStyle: 'italic',
    color: '#666',
    marginBottom: '20px',
  },
  modeSelector: {
    marginBottom: '20px',
    display: 'flex',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #4a90e2',
    backgroundColor: '#4a90e2',
    color: '#fff',
    cursor: 'pointer',
  },
  checkboxContainer: {
    marginBottom: '20px',
  },
  content: {
    borderTop: '1px solid #ddd',
    paddingTop: '20px',
  },
  paragraph: {
    marginBottom: '40px', // Tăng khoảng cách giữa các đoạn văn
  },
  textEn: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '5px',
  },
  textVi: {
    fontSize: '18px',
    color: '#666',
    fontStyle: 'italic',
  },
};

export default ReadingLesson;
