import React from 'react';

const AboutUs = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Giới thiệu về FlashiGo</h1>
      <p style={styles.text}>
      FlashiGo là ứng dụng học tập thông minh, giúp bạn tạo, tổ chức và tùy chỉnh các thẻ học một cách linh hoạt, hỗ trợ tối ưu quá trình học qua việc áp dụng công nghệ AI hiện đại, như ChatGPT, để tự động tạo nội dung thẻ học theo yêu cầu. Bạn có thể yêu cầu thẻ học cho mọi chủ đề, từ tiếng Anh đến các lĩnh vực khác, giúp việc học trở nên phong phú và dễ dàng hơn bao giờ hết.
      </p>
      <p style={styles.text}>
      Với các phương pháp học tiên tiến như spaced repetition (lặp lại cách quãng), FlashiGo giúp bạn cải thiện khả năng ghi nhớ và theo dõi tiến độ học tập hiệu quả, từ bất kỳ thiết bị nào. Bạn có thể tận dụng công cụ này để học mọi lúc, mọi nơi, phù hợp với nhu cầu học của mình.
      </p>
      <p style={styles.text}>
      Dù bạn là học sinh, sinh viên, chuyên gia hay đơn giản là người yêu thích học hỏi, FlashiGo sẽ là người bạn đồng hành lý tưởng giúp bạn mở rộng kiến thức một cách thú vị và hiệu quả. Hãy bắt đầu hành trình học tập của bạn với FlashiGo ngay hôm nay!      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2em',
    marginBottom: '10px',
  },
  text: {
    fontSize: '1.2em',
    lineHeight: '1.5',
    marginBottom: '15px',
  },
};

export default AboutUs;
