

import React from "react";
import { Button, Checkbox, Form, Grid, Input, theme, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import useAuth from '../hooks/useAuth'; // Import useAuth hook
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function Login({onLogin}) {
  const url = process.env.REACT_APP_API_SERVICE_URL
  const gg_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID

  console.log("=== gg_client_id|",gg_client_id)


  const { token } = useToken();
  const screens = useBreakpoint();
  // const { login } = useAuth(); // Destructure the login function from useAuth
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle traditional login form submission
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    // Implement your traditional login flow here
    try {
      const response = await fetch(`${url}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values), // Send email and password
      });

      if (!response.ok) {
        throw new Error("Failed to log in");
      }
      const data = await response.json();
      console.log("data:", data);

      const user_res = await fetch(`${url}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values), // Send email and password
      });

      if (!user_res.ok) {
        throw new Error("Failed to log in");
      }
      const user = await user_res.json();
      console.log("user':", user);

      onLogin(user,data.access_token,data.refresh_token); // Call handleLogin from App
      message.success("Login successful!");

      
    } catch (error) {
      console.error("Login error:", error);
      message.error("Login failed, please try again.");
    }
  };

  const handleGoogleLogin = async (credentialResponse) => {
    console.log("Google login success:", credentialResponse);

    try {
      const response = await fetch(`${url}/api/v1/auth/signin/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_token: credentialResponse.credential,  // Send credential to backend
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to log in with Google");
      }

      const data = await response.json();
      console.log("--data:", data);

      //
      const user_res = await fetch(`${url}/api/v1/user/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+data.access_token,
        },
       
      });

      if (!user_res.ok) {
        throw new Error("Failed to get /user/me");
      }

      const user = await user_res.json();
      console.log("user:", user);
      onLogin(user, data.access_token, data.refresh_token); // Call handleLogin from App
      message.success("Login successful!");
      // navigate('/'); // Redirect to home after successful Google login

    } catch (error) {
      console.error("Login error:", error);
      message.error("Login failed, please try again.");
    }
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px"
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%"
    },
    forgotPassword: {
      float: "right"
    },
    header: {
      marginBottom: token.marginXL
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
    },
    text: {
      color: token.colorTextSecondary
      // marginTop : "10px"
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
    },
    googleButton: {
      width: '100%', // Đảm bảo kích thước rộng full như các button khác
      marginTop: "10px",
    },
    // googleButton: iframe {
    //   width: 100% !important, /* Đảm bảo nút Google Login chiếm toàn bộ chiều rộng của container */
    //   height: 44px !important, /* Chiều cao nhất quán với các nút khác */
    // }
  };

  return (
    <GoogleOAuthProvider clientId={gg_client_id}> {/* Replace with your actual Google client ID */}
      <section style={styles.section}>
        <div style={styles.container}>
          <div style={styles.header}>
            <Title style={styles.title}>Sign in</Title>
            
            <Text style={styles.text}>
              Log in to an existing account.
            </Text>
          </div>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish} // Handle traditional login submission
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              {/* <a style={styles.forgotPassword} href="">
                Forgot password?
              </a> */}
              <Link onClick={() => navigate('/user/password/reset')}>Forgot password?</Link> {/* Link to login */}

            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block type="primary" htmlType="submit">
                Log in
              </Button>

              {/* Google login button */}
              <div style={styles.googleButton}>
                <GoogleLogin
                  // width = {"316px"}
                  width = {"380px"}
                  type = {'standard'}
                  onSuccess={handleGoogleLogin} // Call API after successful Google login
                  onError={() => {
                    message.error("Login failed, please try again.");
                  }}
                />
              </div>

              <div style={styles.footer}>
                <Text style={styles.text}>Don't have an account yet?</Text>{" "}
                {/* <Link href="">Sign up now</Link> */}
                <Link onClick={() => navigate('/sign-up')}>Sign up now</Link> {/* Link to login */}

              </div>
            </Form.Item>
          </Form>
        </div>
      </section>
    </GoogleOAuthProvider>
  );
}
