import React from 'react';
import { Button } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const TodayLessonPage = () => {
  const navigate = useNavigate();

  // Mock data for Today Lesson and All Lessons
  const todayLesson = {
    id: 1,
    title: 'Bài học hôm nay: Từ vựng cơ bản',
  };

  const allLessons = Array.from({ length: 30 }, (_, index) => ({
    id: index + 2, // ID bắt đầu từ 2 vì bài học hôm nay có ID là 1
    title: `Bài học ${index + 2}: Ngữ pháp cơ bản`,
  }));

  // Styles for Today Lesson
  const todayLessonStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    backgroundColor: '#1677ff',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  // Styles for the grid of other lessons
  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
  };

  const lessonCardStyle = {
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  };

  const highlightedLessonCardStyle = {
    ...lessonCardStyle,
    backgroundColor: '#e0f7fa',  // Highlight the 'today's lesson' card
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  };

  // Handle navigation to a selected lesson
  const handleLessonClick = (lessonId) => {
    navigate(`/lesson/${lessonId}`);
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Today's Lesson */}
      <div style={todayLessonStyle}>
        <p>{todayLesson.title}</p>
      </div>

      {/* List of Remaining Lessons */}
      <div style={{ marginTop: '20px' }}>
        <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Cả Bộ Bài Học</h3>
        <div style={gridContainerStyle}>
          {allLessons.map((lesson) => (
            <div
              key={lesson.id}
              style={lesson.id === todayLesson.id ? highlightedLessonCardStyle : lessonCardStyle}
              onClick={() => handleLessonClick(lesson.id)}
            >
              <p>{lesson.title}</p>
              <Button
                icon={<ReadOutlined />}
                type="primary"
                size="small"
                style={{ marginTop: '10px' }}
              >
                Xem Bài
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TodayLessonPage;

