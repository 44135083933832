import React from 'react';
import CardCarousel from './CardCarousel'; // Import CardCarousel component
// import { useEffect, useState } from 'react';
import { useEffect } from 'react';


// Dữ liệu mẫu cho flashcard sets
const flashcardSets = {
  nouns: [
    { id: 1, title: 'Common English Nouns', description: 'Flashcards for common nouns.', topic: 'Nouns', subscribedUsers: 200 },
    { id: 2, title: 'Animal Names', description: 'Learn the names of various animals.', topic: 'Nouns', subscribedUsers: 50 },
    { id: 3, title: 'English Vocabulary Review', description: 'Recent vocabulary cards.', topic: 'General', subscribedUsers: 150 },
    { id: 4, title: 'Physics Formulas', description: 'Review essential physics formulas.', topic: 'Science', subscribedUsers: 95 },
    { id: 5, title: 'Chemistry Basics', description: 'Flashcards on chemistry concepts.', topic: 'Science', subscribedUsers: 120 },
    { id: 6, title: 'History Dates', description: 'Important dates in history.', topic: 'History', subscribedUsers: 80 },
    { id: 7, title: 'History Dates', description: 'Important dates in history.', topic: 'History', subscribedUsers: 80 },
    { id: 8, title: 'History Dates', description: 'Important dates in history.', topic: 'History', subscribedUsers: 80 },
  ],
  verbs: [
    { id: 9, title: 'Common English Verbs', description: 'Flashcards for common verbs.', topic: 'Verbs', subscribedUsers: 220 },
    { id: 10, title: 'Action Verbs', description: 'Learn about action verbs.', topic: 'Verbs', subscribedUsers: 90 },
    { id: 11, title: 'English Vocabulary Review', description: 'Recent vocabulary cards.', topic: 'General', subscribedUsers: 150 },
    { id: 12, title: 'Physics Formulas', description: 'Review essential physics formulas.', topic: 'Science', subscribedUsers: 95 },
    { id: 13, title: 'Chemistry Basics', description: 'Flashcards on chemistry concepts.', topic: 'Science', subscribedUsers: 120 },
    { id: 14, title: 'History Dates', description: 'Important dates in history.', topic: 'History', subscribedUsers: 80 },
  ],
};

const VocabPage = () => {
  // const [vocabs, setVocabs] = useState([]); // State để lưu danh sách books

  // Hàm gọi API
  const fetchBooks = async () => {
    try {
      // const response = await axios.get('http://localhost:8080/books');
      // setVocabs(response.data.books); // Lưu dữ liệu trả về vào state
    } catch (error) {
      console.error('Error fetching books:', error);
    }
  };

  // Gọi API khi component được render
  useEffect(() => {
    fetchBooks();
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      {/* <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} />
      <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} />
      <CardCarousel type = "book_card" title="Cambridge English IELTS" data={books} /> */}

      <CardCarousel type = "card" title="English Vocabulary in Use" data={flashcardSets.nouns} />
      <CardCarousel type = "card" title="Vocabulary Building" data={flashcardSets.verbs} />
      <CardCarousel type = "card" title="Tính từ" data={flashcardSets.verbs} />
      <CardCarousel type = "card" title="Trạng từ" data={flashcardSets.verbs} />
    </div>
  );
};

export default VocabPage;
