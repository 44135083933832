

import React, { useRef } from 'react';
import CardSet from './CardSet';
import BookCard from './BookCard';

const CardCarousel = ({ title, data, type }) => {
  const carouselRef = useRef(null);

  // const scrollLeft = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  //   }
  // };

  // const scrollRight = () => {
  //   if (carouselRef.current) {
  //     carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  //   }
  // };

  const carouselContainerStyle = {
    marginBottom: '20px',
    position: 'relative',
    padding: '0 60px',
  };

  const carouselStyle = {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollBehavior: 'smooth',
    // paddingBottom: '10px',
    padding: '10px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const hideScrollbar = {
    '&::-webkit-scrollbar': { display: 'none' },
  };

  // const arrowStyle = {
  //   position: 'absolute',
  //   top: '50%',
  //   transform: 'translateY(-50%)',
  //   width: '40px',
  //   height: '40px',
  //   borderRadius: '50%',
  //   backgroundColor: 'rgba(0, 123, 255, 0.2)',
  //   border: 'none',
  //   color: 'white',
  //   fontSize: '24px',
  //   cursor: 'pointer',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   zIndex: 1,
  // };

  // const leftArrowStyle = {
  //   ...arrowStyle,
  //   left: '10px',
  // };

  // const rightArrowStyle = {
  //   ...arrowStyle,
  //   right: '10px',
  // };

  return (
    <div style={carouselContainerStyle}>
      <h3>{title}</h3>
      {/* <button style={leftArrowStyle} onClick={scrollLeft}>
        ‹
      </button> */}
      <div style={{ ...carouselStyle, ...hideScrollbar }} ref={carouselRef}>
        {data.map((item) =>
          type === 'card' ? (
            <CardSet
              key={item.id}
              id={item.id}
              title={item.title}
              description={item.description}
              topic={item.topic}
              subscribedUsers={item.subscribedUsers}
            />
          ) : type === 'book_card' ? (
            <BookCard
              key={item.id}
              id={item.id}
              title={item.name}
              image={item.image_url}
              author={item.author}
              description={item.description}
            />
          ) : null
        )}
      </div>
      {/* <button style={rightArrowStyle} onClick={scrollRight}>
        ›
      </button> */}
    </div>
  );
};

export default CardCarousel;
