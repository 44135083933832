import React, { useState } from 'react';
import { Button, Input, Card, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

// import { Button, Input, Card, Modal } from 'antd';
const { TextArea } = Input;


const AICards = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState('');
  const [generatedCards, setGeneratedCards] = useState([]);
  const [addedCards, setAddedCards] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const handleGenerateCards = async () => {
    const newCards = [
      { id: 1, title: 'Flashcard 1', description: 'Description for card 1' },
      { id: 2, title: 'Flashcard 2', description: 'Description for card 2' },
      { id: 3, title: 'Flashcard 3', description: 'Description for card 3' },
    ];
    setGeneratedCards(newCards);
    setAddedCards(newCards); // Mặc định tất cả thẻ đã được thêm vào
  };

  const handleAddCard = (card) => {
    if (!addedCards.includes(card)) {
      setAddedCards((prev) => [...prev, card]);
    }
  };

  const handleRemoveCard = (card) => {
    setAddedCards((prev) => prev.filter((c) => c.id !== card.id));
  };

  const handleFinalize = () => {
    const hasAddedItems = addedCards.length > 0;
    if (!hasAddedItems) {
      setErrorModalVisible(true);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleClearItems = () => {
    setGeneratedCards([]); // Xóa thẻ đã tạo
    setAddedCards([]); // Xóa thẻ đã thêm
  };

  const handleModalOk = (goToYourCards) => {
    setIsModalVisible(false);
    if (goToYourCards) {
      navigate('/your-cards');
    } else {
    //   navigate('/ai-card-creator');
        handleClearItems()
    }
  };

  const handleErrorModalOk = () => {
    setErrorModalVisible(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* <Input
        placeholder="Enter your request for OpenAI..."
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        onPressEnter={handleGenerateCards}
        style={{ marginBottom: '20px' }}
      /> */}
      <TextArea
        placeholder="Enter your request for OpenAI..."
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        onPressEnter={handleGenerateCards}
        autoSize={{ minRows: 3, maxRows: 6 }} // Điều chỉnh kích thước tự động với số dòng tối thiểu và tối đa
        style={{ marginBottom: '20px' }}
      />

      <Button onClick={handleGenerateCards}>Generate Cards</Button>

      <div style={{ marginTop: '20px' }}>
        {generatedCards.map((card) => (
          <Card key={card.id} style={{ marginBottom: '10px' }}>
            <h4>{card.title}</h4>
            <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{card.description}</p>
            <Button
              onClick={() => handleAddCard(card)}
              style={{
                marginRight: '10px',
                backgroundColor: addedCards.includes(card) ? 'lightgreen' : 'transparent',
                color: 'black',
                borderColor: addedCards.includes(card) ? 'lightgreen' : '#d9d9d9',
              }}
            >
              Add
            </Button>
            <Button
              onClick={() => handleRemoveCard(card)}
              style={{
                backgroundColor: addedCards.includes(card) ? 'transparent' : 'lightcoral',
                color: 'black',
                borderColor: addedCards.includes(card) ? '#d9d9d9' : 'lightcoral',
              }}
            >
              Remove
            </Button>
          </Card>
        ))}
      </div>

      <Button type="primary" onClick={handleFinalize} style={{ marginTop: '20px' }}>
        Finalize Cards
      </Button>

      {/* Nút Clear Your Items với khoảng cách vừa phải */}
      <Button onClick={handleClearItems} style={{ marginTop: '10px', marginLeft: '10px' }}>
        Clear Your Items
      </Button>

      <Modal
        title={
          <div style={{ textAlign: 'center' }}>
            <CheckCircleOutlined style={{ color: 'green', fontSize: '24px', marginRight: '8px' }} />
            Success
          </div>
        }
        visible={isModalVisible}
        footer={
          <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <Button
              key="create"
              onClick={() => handleModalOk(false)}
              style={{
                flex: 1,
                color: 'black',
                backgroundColor: 'transparent',
                border: '1px solid black',
              }}
            >
              Continue Creating Cards
            </Button>
            <Button
              key="goToCards"
              type="default"
              onClick={() => handleModalOk(true)}
              style={{
                flex: 1,
                color: 'black',
                backgroundColor: 'transparent',
                border: '1px solid black',
              }}
            >
              Go to Your Cards
            </Button>
          </div>
        }
        centered
        bodyStyle={{ textAlign: 'center', padding: '20px' }}
      >
        <p style={{ textAlign: 'center' }}>All cards have been initialized successfully!</p>
      </Modal>

      {/* Modal lỗi khi không có thẻ để thêm */}
      <Modal
        title="No Items"
        visible={errorModalVisible}
        onOk={handleErrorModalOk}
        onCancel={handleErrorModalOk}
        centered
      >
        <p>No items to add your cards!</p>
      </Modal>
    </div>
  );
};

export default AICards;
