import React, { useState } from 'react';
import { Layout, Menu, ConfigProvider, Button } from 'antd';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { MenuUnfoldOutlined, MenuFoldOutlined, FunctionOutlined } from '@ant-design/icons';
import AppHeader from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ResetPassword from './components/ResetPassword';
import SetNewPassword from './components/SetNewPassword';
import AboutUs from './components/AboutUs';
import FlashcardPage from './components/FlashcardPage';
import Orders from './components/Orders';
import AICards from './components/AICards';

import BookPage from './components/BookPage';
import VocabPage from './components/VocabPage';
import GrammarPage from './components/pages/GrammarPage';
import WritingPage from './components/pages/WritingPage';
import ParaphrasePage from './components/pages/ParaphrasePage';
import TodayLessonPage from './components/pages/TodayLessonPage';
import BookSummaryPage from './components/pages/BookSummaryPage';

import DictationPage from './components/pages/DictationPage';
import IPATraining from './components/dictation/IPATraining';

import UnitPage from './components/dictation/UnitPage';
import Speech from './components/dictation/Speech';
import TextToSpeech from './components/dictation/TextToSpeech';


import PomodoroTimer from './components/utils/PomodoroTimer';


import FlashMode from './components/study/FlashMode';
import MultipleAnswer from './components/study/MultipleAnswer';
import MatchingCard from './components/study/MatchingCard';
import CardWriting from './components/study/CardWriting';
import BookLessonList from  './components/BookLessonList'
import ReadingLesson from './components/ReadingLesson'; // Đường dẫn đến component ReadingLesson

import  WrapperBreadcrumb from './components/breadcrumb/WrapperBreadcrumb'; // Đường dẫn đến component ReadingLesson


import useAuth from './hooks/useAuth'; // Import hook useAuth


// Import icons
import {
  BookOutlined,
  AppstoreAddOutlined,
  // FileTextOutlined,
  // UnorderedListOutlined,
  // AudioOutlined,
  FileWordOutlined ,
  RetweetOutlined,
  SoundOutlined,
  EditOutlined,
  // FunctionOutlined,
  PlusOutlined,
  ClockCircleOutlined,
  OpenAIOutlined,
} from '@ant-design/icons';

import "./App.css";

const { Content, Sider } = Layout;

const App = () => {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false); // Trạng thái sidebar

  const isAuthPage = ['/login', '/sign-up', '/user/password/reset', '/reset-password'].includes(location.pathname);
  console.log("--- isAuthPage|",isAuthPage)

  const handleLogin = (userData,token, refresh_token) => {
    login(userData, token, refresh_token);
    navigate('/'); // Điều hướng về trang chủ sau khi đăng nhập thành công
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const getSelectedKey = (pathname) => {
    // Tách đường dẫn để lấy phần đầu tiên
    const parts = pathname.split('/').filter(Boolean);
    // Phần đầu tiên là key cha cần chọn
    return parts.length > 0 ? `/${parts[0]}` : '/';
  };
  

  const toggleSidebar = () => {
    setCollapsed(!collapsed); // Thay đổi trạng thái sidebar
    console.log('Sidebar collapsed:', !collapsed); // Kiểm tra trạng thái
  };

  const menuItems = [
    { key: '/dictation', label: 'Dictation', icon: <SoundOutlined/> },


    { key: '/card-create', label: 'Tạo', icon: <PlusOutlined />},
    { key: '/ask-ai', label: 'Ask AI', icon: <OpenAIOutlined />},
    { key: '/lesson-daily', label: 'Daily Lesson', icon: <BookOutlined /> },
    // { key: '/vocabs', label: 'Vocabulary', icon: <FileWordOutlined />},
    // { key: '/grammar', label: 'Grammar In Use', icon: <FunctionOutlined /> },
    // { key: '/sentence-recall', label: 'Sentence Recall', icon: <EditOutlined/> },
    // { key: '/speech', label: 'Speech', icon: <EditOutlined/> },
    // { key: '/text-speech', label: 'Text2Speech', icon: <EditOutlined/> },
    // { key: '/pomodoro', label: 'Pomodoro', icon: <ClockCircleOutlined/> },


    // { key: '/recall-write', label: 'Recall & Write', icon: <EditOutlined/> },


    // { key: '/dictation/ipa/', label: 'Pomodoro', icon: <ClockCircleOutlined/> },
    // { key: '/book-summary', label: 'Book Summary', icon: <BookOutlined /> },
    // { key: '/books', label: 'Book', icon: <BookOutlined /> },
    // { key: '/oxford-3000', label: 'Oxford 3000', icon: <AppstoreAddOutlined /> },
    // { key: '/collocations', label: 'Collocations', icon: <AppstoreAddOutlined /> },
    // { key: '/phrasal-verbs', label: 'Phrasal verbs', icon: <AppstoreAddOutlined /> },
    // { key: '/conversations', label: 'Conversations', icon: <SoundOutlined /> },
    // { key:  '/paraphrasing', label: 'Paraphrasing', icon: <RetweetOutlined /> },
    // { key: '/writing', label: 'Writing Practice', icon: <EditOutlined /> },


    // { key: '/grammar', label: 'Grammar', icon: <FileTextOutlined /> },
    // { key: '/paraphrasing', label: 'Paraphrasing', icon: <UnorderedListOutlined /> },
    // { key: '/listening', label: 'Luyện Listening', icon: <SoundOutlined /> },
    // { key: '/thi-thu', label: 'Thi Thử', icon: <UnorderedListOutlined /> },
  ];

  const handleMenuClick = (e) => {
    navigate(e.key); // Điều hướng khi nhấp vào mục menu
  };

 return (
    <ConfigProvider>
      <div className="container">
        
      <header className="header">
        <AppHeader user={user} onLogout={handleLogout}>
            {!isAuthPage && ( // Ẩn nút toggle khi ở trang login
              <Button
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggleSidebar}
                type="text"
                style={{ marginLeft: '16px', color: '#fff' }} // Nút trong Header
              />
            )}
          </AppHeader>
      </header>
      <div className="body">

      <div className="left">

      {!isAuthPage && ( // Ẩn sidebar khi ở trang login hoặc các trang xác thực
            <Sider
              theme="light"
              width={200}
              collapsible
              collapsed={collapsed} // Truyền trạng thái collapsed
              trigger={null}        // Ẩn nút trigger mặc định
              style={{
                height: '100%', // Chiều cao toàn màn hình
                position: 'fixed', // Cố định sidebar
                left: 0,          // Canh trái
                borderRight: '1px solid #f0f0f0',
              }}
            >
              <Menu
                mode="inline"
                selectedKeys={[getSelectedKey(location.pathname)]}
                defaultSelectedKeys={['/']}
                style={{ height: '100%', borderRight: 0 }}
                items={menuItems}
                onClick={handleMenuClick}
              />
            </Sider>
          )}

          </div>
          <div className="center">
          {!isAuthPage && (

            <WrapperBreadcrumb location = {location}></WrapperBreadcrumb>
            

            )}
          <div class="center-content">
            <Content
            style={{
              // marginLeft: !isAuthPage ? (collapsed ? 80 : 180) : 0, // Đẩy nội dung sang phải nếu không phải là trang login
              paddingRight: isAuthPage ? '200px' : '0px',

              // padding: isAuthPage ? '0' : '20px',
              background: '#fff',
              display: isAuthPage ? 'flex' : 'block',
              justifyContent: isAuthPage ? 'center' : 'flex-start',
              alignItems: isAuthPage ? 'center' : 'flex-start',
              height: isAuthPage ? '100%' : '100%',
              // transition: 'margin-left 0.2s ease', // Hiệu ứng khi sidebar collapsed
            }}
          >
            
            <Routes>
              <Route path="/" element={<Home user={user} />} />
              <Route path="/lesson-daily" element={<TodayLessonPage/>} />
              <Route path="/book-summary" element={<BookSummaryPage/>} />
              <Route path="/pomodoro" element={<PomodoroTimer/>} />

              <Route path="/books" element={<BookPage/>} />
              <Route path="/vocabs" element={<VocabPage/>} />
              <Route path="/grammar" element={<GrammarPage/>} />
              <Route path="/paraphrasing" element={<ParaphrasePage/>} />
              <Route path="/dictation" element={<DictationPage/>} /> 
              <Route path="/dictation/ipa/:id" element={<IPATraining/>}/>
              <Route path="/dictation/ipa" element={<UnitPage/>} />
              <Route path="/speech" element={<Speech/>} />
              <Route path="/text-speech" element={<TextToSpeech/>} />



              <Route path="/book/:id/lessons" element={<BookLessonList/>} />
              <Route path="/book/:bookId/:lessonId" element={<ReadingLesson />} />

              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/user/password/reset" element={<ResetPassword />} />
              <Route path="/reset-password" element={<SetNewPassword />} />
              <Route path="/card-create" element={<AICards />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/flashcards/:id" element={<FlashcardPage />} />
              <Route path="/writing" element={<WritingPage />} />

              <Route path="/study/:content-type/flash-mode/:id" element={<FlashMode />} />

              <Route path="/study/:content-type/multiple-answers/:id" element={<MultipleAnswer />} />
              <Route path="/study/:content-type/matching/:id" element={<MatchingCard />} />
              <Route path="/study/:content-type/writing/:id" element={<CardWriting />} />
              <Route path="/orders" element={<Orders />} />
            </Routes>
          </Content>
              
            </div> 
          

          </div>

      </div>
    
        </div>
    </ConfigProvider>
    
  );
};

export default App;
