import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SetNewPassword() {
  const navigate = useNavigate();
  const [validToken, setValidToken] = useState(false);
  const [token, setToken] = useState(null);
  const { search } = useLocation();

  // Extract the token from URL query
  useEffect(() => {
    const params = new URLSearchParams(search);
    const resetToken = params.get('token');
    if (resetToken) {
      setToken(resetToken);
      setValidToken(true); // Ideally, verify token via an API call
    }
  }, [search]);

  const onFinish = async (values) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          new_password: values.password,
          token:token,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to reset password");
      }

      message.success('Password reset successful!');
      navigate('/login'); // Redirect to login page

    } catch (error) {
      console.error('Error:', error);
      message.error('Unable to reset password. Try again.');
    }
  };

  if (!validToken) {
    return <p>Invalid or missing token. Please check your email link.</p>;
  }

  return (
    <div style={{ maxWidth: '400px', margin: 'auto' }}>
      <h2>Set New Password</h2>
      <Form name="set_new_password" onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password!' },
            { min: 6, message: 'Password must be at least 6 characters long!' }
          ]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          dependencies={['password']}
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match!'));
              }
            })
          ]}
        >
          <Input.Password placeholder="Confirm New Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Set New Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
