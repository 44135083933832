import React, { useState } from 'react';
import { useLocation } from "react-router-dom";

const FlashMode = () => {
  const location = useLocation();
  const getData = () =>{
    const data = location.state?.data;
    console.log("getData|",data)
    return data
  }

  // State for current card index
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  // Handle card hover
  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  // Handle card navigation with wrap-around logic
  const handleNext = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const handlePrev = () => {
    setIsFlipped(false);
    setCurrentCardIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const cards = getData()

  const currentCard = cards[currentCardIndex];

  return (
    <div style={containerStyle}>
      <div style={cardContainerStyle}>
        {/* Flashcard with hover effect */}
        <div
          style={{ ...cardStyle, transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Front of the card */}
          <div style={frontStyle}>
            {currentCard.content}
          </div>
          {/* Back of the card */}
          <div style={backStyle}>
            {currentCard.translatedContent}
          </div>
        </div>
      </div>

      {/* Navigation buttons and card index */}
      <div style={buttonContainerStyle}>
        <button style={arrowButtonStyle} onClick={handlePrev}>
          &#8592; {/* Left arrow */}
        </button>
        <span style={indexStyle}>
          {currentCardIndex + 1} / {cards.length}
        </span>
        <button style={arrowButtonStyle} onClick={handleNext}>
          &#8594; {/* Right arrow */}
        </button>
      </div>
    </div>
  );
};

// Updated styles
const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
};

const cardContainerStyle = {
  perspective: '1000px',
  marginBottom: '20px',
};

const cardStyle = {
  width: '500px',
  height: '300px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'transform 1.2s', // Slower flip speed
  transformStyle: 'preserve-3d',
};

const frontStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backfaceVisibility: 'hidden',
  fontSize: '24px',
};

const backStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backfaceVisibility: 'hidden',
  transform: 'rotateY(180deg)',
  fontSize: '24px',
};

const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '300px', // Bring buttons closer
};

const arrowButtonStyle = {
  padding: '10px',
  border: 'none',
  backgroundColor: 'transparent',
  color: '#000', // Black arrows
  fontSize: '30px',
  cursor: 'pointer',
  outline: 'none',
};

const indexStyle = {
  fontSize: '16px', // Smaller size
  color: '#000', // Regular font weight
};

export default FlashMode;
