import React from "react";
import { Button, Form, Grid, Input, theme, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function SignUp() {
  const { token } = useToken();
  const screens = useBreakpoint();
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle signup form submission
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    const { email, password, firstName, lastName } = values; // Lấy firstName và lastName

    // Call your API to create a new user here
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVICE_URL}/auth/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          first_name: firstName, // Gửi first_name
          last_name: lastName, // Gửi last_name
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to sign up");
      }

      // const data = await response.json();
      message.success("Registration successful! You can now log in.");
      navigate('/login'); // Redirect to login page after successful signup

    } catch (error) {
      console.error("Signup error:", error);
      message.error("Signup failed, please try again.");
    }
  };

  const styles = {
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
      width: "380px",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    text: {
      color: token.colorTextSecondary,
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Title style={styles.title}>Sign Up</Title>
          <Text style={styles.text}>
            Create a new account.
          </Text>
        </div>
        <Form
          name="signup"
          onFinish={onFinish} // Handle signup submission
          layout="vertical"
          requiredMark="optional"
        >
          <Form.Item
            name="firstName" // Trường first name
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName" // Trường last name
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            rules={[
              {
                required: true,
                message: "Please confirm your Password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button block type="primary" htmlType="submit">
              Sign Up
            </Button>
            <div style={styles.footer}>
              <Text style={styles.text}>Already have an account?</Text>{" "}
              <Link onClick={() => navigate('/login')}>Sign in now</Link> {/* Link to login */}
            </div>
          </Form.Item>
        </Form>
      </div>
    </section>
  );
}
